/*
* Trunk version 2.0.1
*/

// Main styles
@import "../bootstrap/functions";
@import "../bootstrap/variables";
@import "../bootstrap/mixins";
@import "variables-custom";
@import "mixins";
@import "reset";

// Components
@import "components/type-custom";
@import "components/brand";
@import "components/links";
@import "components/blocks";
@import "components/boxes";
@import "components/groups";
@import "components/units";
@import "components/lists";
@import "components/images";
@import "components/icons";
@import "components/tables";
@import "components/dividers";
@import "components/buttons";
@import "components/forms";
@import "components/posts";
@import "components/quotes";
@import "components/thumbnails";
@import "components/breadcrumbs";
@import "components/pagination-custom";
@import "components/snackbars";
@import "components/footers";
@import "components/layouts";
@import "components/profiles";
@import "components/tours";
@import "components/destinations";
@import "components/comments";
@import "components/jumbotron";
@import "components/price";

// Helpers
@import "helpers/page-layout";
@import "helpers/text-styling";
@import "helpers/offsets";
@import "helpers/sections";
@import "helpers/grid-modules";
@import "helpers/backgrounds"; 
@import "helpers/utilities-custom";

// Plugins
@import "plugins/animate";
@import "plugins/preloader";
@import "plugins/ui-to-top";
@import "plugins/rd-navbar";
@import "plugins/swiper";
@import "plugins/google-map";
@import "plugins/rd-search";
@import "plugins/isotope";
@import "plugins/nav-custom";
@import "plugins/card-custom";
@import "plugins/tooltip-custom";
@import "plugins/counter";
@import "plugins/progress-bars";
@import "plugins/jquery-circle-progress";
@import "plugins/owl-carousel";
@import "plugins/lightgallery";
@import "plugins/material-parallax";
@import "plugins/modal-custom";
@import "plugins/countdown";
@import "plugins/bootstrap-material-datetimepicker";
@import "plugins/rd-range";
@import "plugins/m-parallax";
@import "plugins/slick";
@import "plugins/select2";



