/*
*
* Slick Carousel
*/

//
// Base styles
//
/*rtl:begin:ignore*/

$slick-font-path: "./fonts/" !default;
$slick-font-family: $font-family-base !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: black !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "←" !default;
$slick-next-character: "→" !default;
$slick-dot-character: "•" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}

.slick-list {
	position: relative;
	overflow: hidden;
	display: block;
	margin: 0;
	padding: 0;

	&:focus {
		outline: none;
	}

	&.dragging {
		cursor: pointer;
		cursor: hand;
	}
}

.slick-slider .slick-track,
.slick-slider .slick-list {
	transform: translate3d(0, 0, 0);
}

.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;

	&::before,
	&::after {
		content: "";
		display: table;
	}

	&::after {
		clear: both;
	}

	.slick-loading & {
		visibility: hidden;
	}
}

.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	[dir="rtl"] & {
		float: right;
	}
	img {
		display: block;
	}
	&.slick-loading img {
		display: none;
	}

	display: none;

	&.dragging img {
		pointer-events: none;
	}

	.slick-initialized & {
		display: block;
	}

	.slick-loading & {
		visibility: hidden;
	}

	.slick-vertical & {
		display: block;
		height: auto;
		border: 1px solid transparent;
	}
}

.slick-arrow.slick-hidden {
	display: none;
}

@function slick-image-url($url) {
	@if function-exists(image-url) {
		@return image-url($url);
	} @else {
		@return url($slick-loader-path + $url);
	}
}

@function slick-font-url($url) {
	@if function-exists(font-url) {
		@return font-url($url);
	} @else {
		@return url($slick-font-path + $url);
	}
}

// Appearance styles
// --------------------------------------------------

.slick-list {
	.slick-loading & {
		background: $white slick-image-url("../images/ajax-loader.gif") center center no-repeat;
	}
}

/* Icons */
@if $slick-font-family == "slick" {
	@font-face {
		font-family: "slick";
		src: slick-font-url("slick.eot");
		src: slick-font-url("slick.eot?#iefix") format("embedded-opentype"), slick-font-url("slick.woff") format("woff"), slick-font-url("slick.ttf") format("truetype"), slick-font-url("slick.svg#slick") format("svg");
		font-weight: normal;
		font-style: normal;
	}
}

/* Arrows */

.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	height: 20px;
	width: 20px;
	line-height: 0;
	font-size: 0;
	cursor: pointer;
	background: rgba($black, .6);
	color: transparent;
	top: 50%;
	transform: translate(0, -50%);
	padding: 0;
	border: none;
	outline: none;
	z-index: 9;

	&:hover, &:focus {
		outline: none;
		background: transparent;
		color: transparent;
		&:before {
			opacity: $slick-opacity-on-hover;
		}
	}
	&.slick-disabled:before {
		opacity: $slick-opacity-not-active;
	}
}

.slick-prev:before, .slick-next:before {
	font-family: $slick-font-family;
	font-size: 20px;
	line-height: 1;
	color: $slick-arrow-color;
	opacity: $slick-opacity-default;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-prev {
	left: 0;
	[dir="rtl"] & {
		left: auto;
		right: 0;
	}
	&:before {
		content: $slick-prev-character;
		[dir="rtl"] & {
			content: $slick-next-character;
		}
	}
}

.slick-next {
	right: 0;
	[dir="rtl"] & {
		left: 0;
		right: auto;
	}
	&:before {
		content: $slick-next-character;
		[dir="rtl"] & {
			content: $slick-prev-character;
		}
	}
}

/* Dots */

.slick-slider {
}

.slick-dots {
	li {
		display: inline-block;
		vertical-align: middle;
    font-size: 0;
		line-height: 0;
		margin: 0 12px;
		
		button {
			@include reset-button;
			position: relative;
			width: 9px;
			height: 9px;
			border-radius: 50%;
			background: $gray-300;
			text-align: center;
			outline: none;
			cursor: pointer;
			transition: .2s;

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate3d(-50%, -50%, 0);
				width: 200%;
				height: 200%;
				border-radius: 50%;
			}
		}

		&:only-child {
			display: none;
		}
	}
	
	.slick-active button,
	li button:hover {
		background-color: $primary;
	}
}

/*rtl:end:ignore*/


.slick-slider-1 {
	
}

//
// Owl 4
//
.slick-slider-1 {
	* {
		backface-visibility: hidden;
	}
	
	.slick-list {
		padding: 30px;
		margin: -30px;
	}

	.slick-track {
		display: flex;
		align-items: center;
	}

	.slick-slide {
		opacity: 0;
		visibility: hidden;
		transition: .4s;
		will-change: opacity, transform;
		transform: translate3d(0,0,0);
	}
  
  //.ipad &,
  //.iphone & {
  //  .slick-slide {
  //    transition: none;
  //  }
  //}

	.slick-active {
		opacity: 1;
		visibility: visible;
	}
	
	.slick-dots {
		margin-top: 20px;
	}
}

* + .slick-slider-1 { margin-top: 30px; }

@include media-breakpoint-down(xs) {
	.slick-slider-1 {
		.slick-slide {
			> * {
				max-width: 370px;
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

@include media-breakpoint-up(sm) {
	.slick-slider-1 {
		.slick-slide {
			position: relative;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			transition: transform .33s, opacity .6s, visibility .6s;
			transform: scale(1);

			> * {
				min-width: 370px;
				margin: 0;
				opacity: .4;
				
			}
		}

		// 1, 3
		.slick-slide:not(.slick-center) {
			transform: scale(.91);
		}

		// 1
		.slick-active:not(.slick-center) { }

		// 2
		.slick-center {
			align-items: center;
			z-index: 2;
			background: $white;
			transition: .3s;

			> * {
				min-width: 420px;
				opacity: 1;
			}
		}

		.slick-center + .slick-active {
			align-items: flex-end;
		}
	}

	* + .slick-slider-1 { margin-top: 40px; }
}

@include media-breakpoint-up(lg) {
	.slick-slider-1 {
		.slick-slide {
			> * {
				min-width: 430px;
			}
		}

		// 2
		.slick-center {
			> * {
				min-width: 520px;
			}
		}

		 .slick-dots {
			margin-top: 35px;
		}
	}
}

@include media-breakpoint-up(xl) {
	.slick-slider-1 {
		.slick-dots li {
			margin: 0 22px;
			
			button {
				width: 12px;
				height: 12px;
			}
		}
	}
}

@include media-breakpoint-up(xxl) {
	.slick-slider-1 {
		.slick-dots {
			margin-top: 85px;
		}
	}
	* + .slick-slider-1 { margin-top: 85px; }
}
