/*
* Typography
*/

// Body
body {
	font-family: $font-family-base;
	font-size: 14px;
	font-weight: $font-weight-base;
	line-height: 1.7;
	color: $body-color;
	background-color: $body-bg;
	-webkit-text-size-adjust: none;
	-webkit-font-smoothing: subpixel-antialiased;

	
	@include media-breakpoint-up(md) {
		font-size: $font-size-body;
		line-height: $line-height-base;
		
	}
}

// Headings
#{headings()} {
	font-family: $font-family-base;
	margin-top: 0;
	margin-bottom: 0;
	font-weight: $headings-font-weight;
	color: $headings-color;
	letter-spacing: -0.02em;


	a {
		color: inherit;

		&:hover {
			color: $primary;
		}
	}
}

h1,
.heading-1 {
	font-size: 32px;
	line-height: 1.5;
	
	@include media-breakpoint-up(md) {
		font-size: 40px; 
	}
	
	@include media-breakpoint-up(lg) {
		font-size: 56px; 
	}

	@include media-breakpoint-up(xl) {
		font-size: $h1-font-size;
		line-height: $h1-line-height;
	}
}

h2,
.heading-2 {
	font-size: 28px;
	line-height: 1.3;
	
	@include media-breakpoint-up(md) {
		font-size: 36px; 
	}
	
	@include media-breakpoint-up(lg) {
		font-size: 40px; 
	}

	@include media-breakpoint-up(xl) {
		font-size: $h2-font-size;
		line-height: $h2-line-height;
	}
}

h3,
.heading-3 {
	font-size: 22px;
	line-height: 1.5;
	
	
	@include media-breakpoint-up(md) {
		font-size: 26px;
		line-height: $h3-line-height;
	}

	@include media-breakpoint-up(xl) {
		font-size: $h3-font-size;
	}
}

h4,
.heading-4 {
	font-size: 22px;
	line-height: 1.3;
	letter-spacing: .01em;
	
	@include media-breakpoint-up(md) {
		font-size: 21px; 
	}

	@include media-breakpoint-up(xl) {
		font-size: $h4-font-size;
		line-height: $h4-line-height;
	}
}

h5,
.heading-5 {
	font-size: 16px;
	line-height: 1.4;
	@include media-breakpoint-up(md) {
		font-size: $h5-font-size;
		line-height: $h5-line-height; 
	}
}

h6,
.heading-6 {
	font-size: 15px;
	line-height: $h6-line-height;
	@include media-breakpoint-up(sm) {
		font-size: $h6-font-size;
	}
}

.heading-7 {
	font-size: 16px;
	line-height: 1.3;
	font-weight: 300;
	text-transform: uppercase;
	letter-spacing: .25em;
	
	@include media-breakpoint-up(md) {
		font-size: 18px;
		line-height: (24 / 18);
	}
}

// Emphasis
small,
.small {
	display: block;
	font-size: $font-size-sm;
	line-height: $line-height-sm;
}

mark,
.mark {
	padding: 3px 5px;
	color: $white;
	background: $primary;
}

// Big text
.big {
	font-size: 15px;
	line-height: 1.6;
	
	@include media-breakpoint-up(sm) {
		font-size: 16px; 
	}
	
	@include media-breakpoint-up(md) {
		font-size: $font-size-lg;
		line-height: $line-height-lg; 
	}
}

.smaller {
	font-size: 15px;
}

.text-xxl {
	font-size: 120px;
	line-height: .9;
	font-weight: 700;
	letter-spacing: -.02em;
	
	@include media-breakpoint-up(md) {
		font-size: 150px; 
	}
	
	@include media-breakpoint-up(xl) {
		font-size: 200px; 
	} 
}

.lead {
	font-size: $lead-font-size;
	line-height: $lead-line-height;
	font-weight: $lead-font-weight;
}

code {
	padding: $code-padding-y $code-padding-x;
	border-radius: $border-radius-sm;
	font-size: $code-font-size;
	color: $code-color;
	background: $code-bg;
}

// Paragraph
p {
	[data-toggle='tooltip'] {
		padding-left: .25em;
		padding-right: .25em;
		color: $primary;
	}

	[style*='max-width'] {
		display: inline-block;
	}
}

// Selection
::selection {
	background: $primary;
	color: $white;
}

::-moz-selection {
	background: $primary;
	color: $white;
}


.text-1 {

	font-size: 40px;
	line-height: 1;
	letter-spacing: -.04em;

	@include media-breakpoint-up(sm) {
		font-size: 54px;
	}

	@include media-breakpoint-up(md) {
		font-size: 70px;
	}

	@include media-breakpoint-up(lg) {
		font-size: 80px;
	}

	@include media-breakpoint-up(xl) {
		font-size: 90px;
	}
}

* + .text-1 { margin-top: 10px; }

@include media-breakpoint-up(md) {
	* + .text-1 { margin-top: 15px; }
	.text-1 + h4 { margin-top: 10px; }
}

@include media-breakpoint-up(xl) {
	* + .text-1 { margin-top: 30px; }
	.text-1 + h4 { margin-top: 26px; }
}

//
// Text 2
//
.text-2 {
	font-size: 16px;
	font-weight: $headings-font-weight;
	color: $gray-500;
}

* + .text-2 { margin-top: 15px; }

@include media-breakpoint-up(md) {
  .text-2 {
		font-size: 18px;
	} 
}

@include media-breakpoint-up(xl) {
  .text-2 {
		font-size: 21px;
	} 
} 

@include media-breakpoint-up(xxl) {
  .text-2 { 
		font-size: 24px;
	} 
} 

.font-base {
	font-family: $font-family-base;
}

.ls-0 {
	letter-spacing: 0;
}

.ls-1 {
	letter-spacing: -0.02em;
}

.ls-3 {
	letter-spacing: 0.03em;
}

.parallax-heading{
	display: flex;
	flex-direction: column;
	font-weight: 700;
	color: $white;
  line-height: 1.4;
	letter-spacing: -0.01em;
	text-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
	align-items: flex-start;
}
.parallax-subtitle{
	max-width: 360px;
	width: 100%;
	text-align: left;
	font-size: 20px;
	letter-spacing: .02em;
	color: $white;
}