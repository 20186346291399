/*
*
* Counter
*/

//
// Table of Contents
//
// Counter Classic
//


//
// Counter Classic
//
.counter-classic {

}

.counter-classic-title {
  font-weight: 400;
  font-family: $font-family-base;
  max-width: 200px;
  letter-spacing: .02em;
  color: #000;
}

.counter-classic-main {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: .02em;
  color: $primary;
  line-height: 1.5;
}

* + .counter-classic-title { margin-top: 0; }

@include media-breakpoint-up(lg) {
  .counter-classic-main {
    font-size: 40px;
  }
}
 
@include media-breakpoint-up(xl) {
  .counter-classic-main {
    font-size: 47px;
  }
}

.counter-classic-2{
  &.counter-classic-2 + &.counter-classic-2{
    margin-left: 20px;
    // Medium ≥768px
    @include media-breakpoint-up(sm) {
      margin-left: 50px;
    }
    @include media-breakpoint-up(xl) {
      margin-left: 120px;
    }
  }
}