//
// RD Range
// 

.rd-range {
	display: flex;
	align-items: center;
	padding-top: 25px;

	&__wrap {
		position: relative;
		width: 100%;
		background-color: $gray-300;
		transition: background 100ms cubic-bezier(0.23, 1, 0.32, 1);
		height: 5px;
	}

	&:hover {
		.rd-range__pointer {
			&:before {
				transform: scale(1);
				animation-name: pulse;
			}
		}
	}

	// Range Pointer
	&__pointer {
		position: absolute;
		cursor: pointer;
		top: 1px;
		left: 6%;
		z-index: 1;
		width: 15px;
		height: 15px;
		border-radius: 50%;
		transform: translate(-50%, -50%);
		transform-origin: 0% 15%;
		transition: transform .2s cubic-bezier(.35, 0, .25, 1);
		overflow: visible;
		background-color: $primary;
		background-clip: padding-box;

		@include media-breakpoint-up(md) {
			&:before {
				content: '';
				height: 26px;
				width: 26px;
				pointer-events: none;
				background: rgba($primary, 0.16);
				position: absolute;
				top: -7px;
				left: -7px;
				border-radius: 50%;
				transform: scale(0);
				transition: transform 100ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0ms, opacity 60ms cubic-bezier(0.445, 0.05, 0.55, 0.95) 0ms;
				pointer-events: none;
				animation-duration: 1s;
				animation-fill-mode: both;
				animation-iteration-count: infinite;
			}

			&.active {
				transform: scale3d(1.5, 1.5, 1.5) translate(-50%, -50%);

				&:before {
					animation: none;
					transform: scale(0);
					opacity: 0;
				}
			}
		}
	}

	// Range line
	&__line {
		position: absolute;
		top: 0;
		left: 0;
		height: 5px;
		background-color: $primary;
	}

	// Range with tooltip
	&.hasTooltip {
		.rd-range__pointer-tooltip {
			font-size: 12px;
			color: #ffffff;
			opacity: 0;
			transition: color .3s cubic-bezier(.35, 0, .25, 1);
		}

		.rd-range__pointer {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: -34px;
			width: 34px;
			height: 34px;
			border-radius: 28px;
			transform: scale(.4) translate3d(0, 67.5px, 0);
			transition: transform .3s cubic-bezier(.35, 0, .25, 1);

			&:before {
				content: none;
			}

			&:after {
				position: absolute;
				content: '';
				left: 0;
				top: 19px;
				z-index: -1;
				border-radius: 16px;
				border-left: 14px solid transparent;
				border-right: 14px solid transparent;
				border-top: 16px solid $primary;
				opacity: 0;
				transform: translate3d(0, -8px, 0);
				transition: all .2s cubic-bezier(.35, 0, .25, 1);
			}

			&.active {
				top: -38px;
				margin-left: -3px;
				opacity: 1;
				transform: translate3d(0, 0, 0) scale(1) translateX(-14px);

				&:after {
					top: 24px;
					left: 3px;
					opacity: 1;
					transform: translate3d(0, 0, 0) scale(1);
				}

				.rd-range__pointer-tooltip {
					opacity: 1;
				}
			}
		}
	}
}


.rd-range-group-modern {

	.rd-range-info {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
  }
	
	.rd-range-info-text {
		color: $gray-500;
	}

	.rd-range-values {
		display: inline-flex;
		align-items: center;
		text-align: center;
		color: $black;
		font-size: 15px;
	}

	.rd-range-input-value-1,
	.rd-range-input-value-2 {
		flex-shrink: 0;
		display: inline-block;
		vertical-align: middle;
		width: 42px;
		border: 0;
		-webkit-appearance: none;
		background-color: transparent;
		text-align: center;
	}

	.rd-range-values-separator {
		padding: 0 7px;
	}
	
	* + .rd-range-info { margin-top: 25px; }
	
	@include media-breakpoint-up(md) {
		* + .rd-range-info { margin-top: 40px; } 
	}
	
	@include media-breakpoint-between(md, md) {
		.rd-range-input-value-1,
		.rd-range-input-value-2 {
			width: 53px;
		}
	}
	
	@include media-breakpoint-between(lg, lg) {
		.rd-range-input-value-1,
		.rd-range-input-value-2,
		.rd-range-info-text {
			font-size: 14px;
		}

		.rd-range-input-value-1,
		.rd-range-input-value-2 {
			width: 49px;
		}
	}
	
	@include media-breakpoint-up(xl) {
		.rd-range-values {
			font-size: 16px;
    }
	} 
}
