/*
* Text styling
*/

// Font types

// Text styles
.text-italic {
	font-style: italic;
}

.text-normal {
	font-style: normal;
}

// Text decoration
.text-underline {
	text-decoration: underline;
}

.text-strike {
	text-decoration: line-through;
}

// Text weight
.font-weight-thin {
	font-weight: 100;
}

.font-weight-light {
	font-weight: 300;
}

.font-weight-regular {
	font-weight: 400;
}

.font-weight-medium {
	font-weight: 500;
}

.font-weight-sbold {
	font-weight: 600;
}

.font-weight-bold {
	font-weight: 700;
}

.font-weight-ubold {
	font-weight: 900;
}

// Text spacing
.text-spacing-0 {
	letter-spacing: 0;
}

.ls-1 {
	letter-spacing: .03em;
}

html .page {
	.text-primary {
		color: $primary !important;
	}

	.text-primary-2 {
		color: $primary-2 !important;
	}

	.text-secondary {
		color: $secondary;
	}

	.text-tertiary {
		color: $tertiary;
	}

	.text-gray-800 {
		color: $gray-800;
	}

	.text-gray-500 {
		color: $gray-500;
	}

	.text-gray-650 {
		color: $gray-650;
	}

	.text-black {
		color: $black;
	}
	.text-white {
		color: $white;
	}
	.text-black-half{
		color: rgba($black,.5);
	}
	.text-black-half-2{
		color: rgba($black,.7);
	}
	.black-2{
		color: #353535;
	}
}

.text-shadow-1 {
	text-shadow: 0 4px 15px rgba($black, .15);
}

.text-decor-1{
	position: relative;
	z-index: 1;
	&:before{
		content: '';
		position: absolute;
		left: 0;
		bottom: 3px;
		background-color: $tertiary;
		opacity: 0.1;
		width: 112px;
		height: 16px;
		z-index: -1;
	}
	&.decor-centered{
		&:before{
			left: 50%;
			transform: translateX(-50%);
		}
	}
	&.left-middle{
		&:before{
			top: 33%;
			bottom: auto;
			transform: translateY(-50%);
		}
	}
}

.text-decor-2{
	position: relative;
	&:before{
		content: '';
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		bottom: 1px;
		background-color: rgba($white, .2);
		width: 112px;
		height: 16px;
		z-index: 1;
	}
}