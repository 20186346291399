/*
*
* RD Navbar
*/

//
// RD Navbar variables
//
$rd-navbar-static-width: map-get($container-max-widths, xl);
$navbar-static-nav-indent: 80px;

$rd-navbar-min-font-size: 24px;
$rd-navbar-min-line-height: 48px;
$rd-navbar-min-height: 56px;

$rd-navbar-color: $black;
$rd-navbar-background: $white;
$rd-navbar-shadow: 0 0 22px -4px rgba(0, 0, 0, 0.17);
$rd-navbar-border: 1px solid $gray-200;
$rd-navbar-megamenu-width: 980px;

// RD Navbar Panel
$rd-navbar-panel-color: $rd-navbar-color;
$rd-navbar-panel-background: $rd-navbar-background;

// RD Navbar Nav
$rd-navbar-nav-min-width: 270px;
$rd-navbar-nav-color: $rd-navbar-color;
$rd-navbar-nav-background: transparent;
$rd-navbar-nav-hover-color: $primary;
$rd-navbar-nav-hover-background: transparent;
$rd-navbar-nav-active-color: $rd-navbar-nav-hover-color;
$rd-navbar-nav-active-background: $rd-navbar-nav-hover-background;

// RD Navbar Dropdown
$rd-navbar-dropdown-width: 230px;
$rd-navbar-dropdown-background: $white;
$rd-navbar-dropdown-item-color: $black;
$rd-navbar-dropdown-item-background: transparent;
$rd-navbar-dropdown-item-hover-color: $primary;
$rd-navbar-dropdown-item-hover-background: transparent;
$rd-navbar-dropdown-item-active-color: $rd-navbar-dropdown-item-hover-color;
$rd-navbar-dropdown-item-active-background: $rd-navbar-dropdown-item-hover-background;

// RD Navbar Megamenu
$rd-navbar-megamenu-gutter: 30px;
$rd-navbar-megamenu-background: $white;
$rd-navbar-megamenu-item-color: $black;
$rd-navbar-megamenu-item-background: transparent;
$rd-navbar-megamenu-item-hover-color: $primary;
$rd-navbar-megamenu-item-hover-background: transparent;

// RD Navbar toggles
$rd-navbar-toggle-preset: "button-lines-cross-2";
$rd-navbar-toggle-border-radius: 0;
$rd-navbar-collapse-toggle-preset: "collapse-preset-1";
$rd-navbar-toggle-color: $gray-700;

@import "rd-navbar_includes/_rd-navbar-mixins";

// RD Navbar Basics
%rd-navbar-transition {
	transition: 0.35s all cubic-bezier(0.65, 0.05, 0.36, 1);
}

.rd-navbar--no-transition {
	&, * {
		transition: none !important;
	}
}

.rd-navbar-wrap {
	@extend %rd-navbar-transition;
}

.rd-navbar,
.rd-navbar.rd-navbar--is-clone {
	display: none;
}

.rd-navbar,
.rd-menu,
.rd-navbar-nav,
.rd-navbar-panel {
	@extend %rd-navbar-transition;
}

.rd-navbar.rd-navbar-fixed + .rd-navbar.rd-navbar--is-clone,
.rd-navbar.rd-navbar-sidebar + .rd-navbar.rd-navbar--is-clone {
	display: none;
}

// RD Navbar
.rd-navbar {
	display: none;
	background: $rd-navbar-background;
}

// RD Navbar Toggle
.rd-navbar-toggle {
	@include reset-button;
	@include make-toggle(
			$rd-navbar-toggle-preset,
			32px,
			$border-radius,
			2px,
			10px,
			$rd-navbar-toggle-color
	);
	background-color: transparent;
	border: none;
	display: none;
}


// RD Navbar Brand
.rd-navbar-brand {
	a {
		display: block;
	}
}

// RD Navbar Nav
.rd-nav-link {
	font-size: 15px;
	font-weight: 700;
	letter-spacing: .05em;
}

// RD Megamenu
.rd-megamenu-title {
	font-size: 18px;
	color: $headings-color;
	font-weight: $headings-font-weight;
}

// RD Navbar Dropdown
.rd-navbar-dropdown {
	display: none;
}

// RD Navbar Collapse
.rd-navbar-collapse-toggle {
	@include reset-button;
	display: flex;
	align-items: center;
	cursor: pointer;
	
	> * + * {
		margin-left: 15px;
		margin-top: 0;
	}
}

.rd-navbar-collapse-toggle-element {
	@include make-toggle(
									'button-lines-cross-2',
									38px,
									0,
									2px,
									16px,
									$black
	);
}

.rd-navbar-collapse-toggle-text {
	position: relative;
	top: -2px;
	font-size: 18px;
	font-weight: 600;
	color: rgba($black, .4);
}

.rd-navbar-collapse-close {
	@include reset-button;
	display: block;
	
	.rd-navbar-collapse-toggle-element span {
		&::before,
		&::after {
			transition: .22s;
		}
	}
	
	&:hover {
		.rd-navbar-collapse-toggle-element span {
			&::before,
			&::after {
				background: $primary;
			}
		}
	}
}

.context-dark {
	.rd-navbar-collapse-toggle .rd-navbar-collapse-toggle-element {
		@include make-toggle(
										'button-lines-cross-2',
										38px,
										0,
										2px,
										16px,
										$white
		);
	}

	.rd-navbar-collapse-toggle-text {
		color: $white;
	}
}

.rd-navbar-collapse {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	width: 440px;
	height: 100vh;
	max-width: 90%;
	padding: 20px 15px;
	text-align: center;
  background: $white;
	z-index: $zindex-rd-navbar;
	box-shadow: 0 4px 20px 0 rgba($black, .25);
	transform: translate3d(20px, 0, 0);
  transition: .22s;
	opacity: 0;
	visibility: hidden;
	overflow-x: hidden;
	overflow-y: auto;
	pointer-events: auto;

	.rd-navbar-collapse-close {
		margin-left: auto;
	}
	
	&.active {
		opacity: 1;
		visibility: visible;
		transform: translate3d(0 ,0, 0);
	}
}

.page div.rd-navbar-collapse {
	&:not(.active) * {
		pointer-events: none;
	}
}

@include media-breakpoint-up(sm) {
	.rd-navbar-collapse {
		padding: 45px;
	}
}

@include media-breakpoint-up(xl) {
	.rd-navbar-collapse-close + * { margin-top: 55px; }
} 

@import "rd-navbar_includes/rd-navbar-static";
@import "rd-navbar_includes/rd-navbar-fixed";

// Themes
@import "rd-navbar_includes/rd-navbar_themes/rd-navbar-classic";
@import "rd-navbar_includes/rd-navbar_themes/rd-navbar-creative";
@import "rd-navbar_includes/rd-navbar_themes/rd-navbar-modern";


