/*
* Breadcrumbs
*/
$breadcrumb-padding-vertical: 8px;
$breadcrumb-padding-horizontal: 15px;
$breadcrumb-bg: $gray-900;
$breadcrumb-color: $primary;
$breadcrumb-active-color: $white;
$breadcrumb-separator: "\f105";

.breadcrumbs-custom {
	position: relative;
	display: flex;
	text-align: center;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(225deg, rgba(21, 21, 21, 0.4) 36.97%, rgba(21, 21, 21, 0.26) 100%);
	}
}

.breadcrumbs-custom-image {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.breadcrumbs-custom-inner {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 100%;
	min-height: (490 / 1920) * 100vw;
	padding: 35px 0 40px;
} 

.breadcrumbs-custom-main {
	position: relative;
	z-index: 1;
	max-width: 700px;
	margin-left: auto;
	margin-right: auto;
}

.breadcrumbs-custom-line {
	display: inline-block;
	margin-left: auto;
	margin-right: auto;
	stroke: $primary;
}

.breadcrumbs-custom-title {
	font-weight: 700;
	text-shadow: 0 4px 4px rgba(21, 21, 21, 0.25);
}

* + .breadcrumbs-custom-title { margin-top: 10px; }

.context-dark {
	.breadcrumbs-custom-line {
		stroke: $white;
	}
}

@include media-breakpoint-up(md) {
	.breadcrumbs-custom-inner {
		padding: 70px 0 100px;
	}
}

@include media-breakpoint-up(lg) {
	.breadcrumbs-custom {
		* + .group { margin-top: 45px; }
	}

	* + .breadcrumbs-custom-title { margin-top: 20px; } 
}


@include media-breakpoint-up(xl) {
	.breadcrumbs-custom-inner {
		padding: 60px 0 150px;
	}
}

@media (min-width: 2050px) {
	.breadcrumbs-custom-inner {
		min-height: 490px;
	}
}
