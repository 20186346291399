/*
*
* Jumbotron
*/

//
// Table of Contents
//
// Jumbotron 1
// Jumbotron 2
// Jumbotron 3
//

//
// Jumbotron 1
//
.jumbotron-1 {

}

.jumbotron-1-aside {
	text-align: center;
	position: absolute;
	z-index: 2;
	bottom: -130px;
	width: 100%;
	padding-right: 15px;
	pointer-events: none;
	@media (min-width: 580px) {
		bottom: -60px;
	}
}

.jumbotron-1-group-item {
	position: relative;
	display: flex;
}

.jumbotron-1-group-item-toggle {
	@include reset-button;
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 47px;
	height: 47px;
	border-radius: 50%;
	font-size: 22px;
	line-height: 1;
	color: $accent-blue;
	transition: .22s;
	pointer-events: auto;
	.icon{
		// Extra large ≥1200px
		@include media-breakpoint-up(xl) {
			font-size: 22px;
		}
	}
  @include media-breakpoint-up(lg) {
    @include link($white , $accent-blue)
	}
}

[data-x-mode='true'] .jumbotron-1-group-item-toggle {
	color: $accent-blue;
}


.jumbotron-1-group-item-toggle-text {
	font-size: 18px;
	line-height: 1.2;
	letter-spacing: -.03em;
	font-weight: 600;
}

.jumbotron-1-group-item-content {
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 30px;
	background: $white;
	box-shadow: 0 5px 15px 0 rgba($black, 0.15);
	width: 100%;
	min-width: 124px;
  height: 100%;
	a {
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 5px 20px 5px 47px;
		height: 47px;
		color: $black;
		font-size: 13px;
		font-weight: 600;
		text-transform: uppercase;
		font-family: $font-family-sec;
		[data-x-mode='true'] & {
			pointer-events: auto;
		}

		&:hover {
			color: $primary;
		}
	}
	[data-x-mode='true'] & {
		opacity: 1;
		visibility: visible;
	}
}

.jumbotron-1-group {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-left: 0 !important;
	@include media-breakpoint-down(md) {
		pointer-events: auto;
	}

	@include group(15px);

	> * {
		width: calc(50% - 15px);
	}
}

@media (min-width: 580px) {
	.jumbotron-1-group {

		> * {
			width: calc(24% - 15px);
			margin-left: auto;
			margin-right: auto;
		}
	}
}

* + .jumbotron-1-aside {
	margin-top: 30px;
}

@include media-breakpoint-up(sm) {
	.jumbotron-1-group {

	}
}



@include media-breakpoint-up(lg) {
	.jumbotron-1 {
		padding-left: 30px;
		padding-right: 30px;
	}

	.jumbotron-1-inner {
		display: flex;
		align-items: center;
		flex-direction: row-reverse;
		justify-content: space-between;
		width: 100%;
	}

	.jumbotron-1-aside {
		position: absolute;
		z-index: 2;
		flex-shrink: 0;

		@include media-breakpoint-up(lg) {
			left: calc(50% - 98% / 2);
			top: 33%;
		}
		@include media-breakpoint-up(xxl) {
			left: calc(50% - 1570px / 2);
			top: 42%;
		}

	}

	.jumbotron-1-main {
		position: relative;
		flex-shrink: 1;
		width: calc(100% - 100px);
	}

	.jumbotron-1-group {

		@include group-media(0, 22px);

		> * {

			width: 100%;
		}
	}

	.jumbotron-1-group-item {
		&.focus {
			z-index: 2;
			.jumbotron-1-group-item-toggle {
				color: $primary;
				border-color: $white;
			}
		}
	}

	.jumbotron-1-group-item-toggle {
		border: 2px solid $white;

		&:hover {
			~ .jumbotron-1-group-item-content {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.jumbotron-1-group-item-content {
		width: auto;
		opacity: 0;
		visibility: hidden;
		transition: .22s;


		a {
			padding-left: 60px;
		}

		&:hover {
			opacity: 1;
			visibility: visible;
		}
	}
}

@include media-breakpoint-up(xl) {
	.jumbotron-1-main {
		width: calc(100% - 100px);
	}
}

@include media-breakpoint-up(xxl) {
	.jumbotron-1 {

	}

	.jumbotron-1-main {
		width: 82%;
	}

	.jumbotron-1-group-item-toggle {
		width: 48px;
		height: 48px;
		@include media-breakpoint-up(xl) {
			width: 55px;
			height: 55px;
		}
	}

	.jumbotron-1-group-item-content a {
		height: 48px;
		// Extra large ≥1200px
		@include media-breakpoint-up(xl) {
			height: 55px;
		}
	}
}

// 
// Jumbotron 2
//
.jumbotron-2 {
	.svg-wave-line {
		stroke: $white;
	}

	.rd-navbar-wrap {
		position: relative;
		z-index: $zindex-rd-navbar;
	}

	.rd-navbar-static {
		background-color: transparent;
	}
}

.jumbotron-2-content {
	position: relative;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: .35;
		background: linear-gradient(45deg, rgba($black, 0) 0%, $black 100%);
	}

	&::after {
		content: '';
		position: absolute;
		left: 0;
		bottom: -1px;
		right: 0;
		height: 30%;
		max-height: 430px;
		background: linear-gradient(to bottom, rgba($white, 0) 0%, $white 100%);
	}

	> * {
		position: relative;
		z-index: 1;
	}
}

.jumbotron-2-content-inner {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	padding: 30px 0 140px;
}

.jumbotron-2-block {
	position: relative;
	z-index: 2;
	padding: 20px 0 60px;
	margin: -100px 15px 0;
	background: $white;
	border-radius: 8px 8px 0 0;

	> .container {
		border: 1px solid transparent;

		> .row {
			position: relative;
			top: 1px;
		}
	}
}

.jumbotron-2-text {
	max-width: 520px;
}

* + .jumbotron-2-text {
	margin-top: 10px;
}

@include media-breakpoint-up(md) {
	.jumbotron-2-content-inner {
		padding: 80px 0 200px;
	}
	.jumbotron-2-block {
		padding-bottom: 90px;
	}
}

@include media-breakpoint-up(xl) {
	.jumbotron-2 {
		* + .text-1 {
			margin-top: 40px;
		}
		* + .rd-form {
			margin-top: 60px;
		}
	}

	.jumbotron-2-content-inner {
		padding-bottom: 625px;
	}

	.jumbotron-2-block {
		padding-bottom: 135px;
		margin-top: -510px;
	}

	* + .jumbotron-2-text {
		margin-top: 35px;
	}
}

@include media-breakpoint-up(xxl) {
	.jumbotron-2-block {
		max-width: 1370px;
		margin-left: auto;
		margin-right: auto;
	}
}

//
// Jumbotron 3
//
.jumbotron-3 {
	display: flex;

	&::after {
		content: '';
		padding-bottom: (778 / 1783) * 100%;
		opacity: 0;
		visibility: hidden;
	}

	.svg-wave-line {
		position: relative;
		top: -.1em;
		stroke: $white;
	}
}

.jumbotron-3::after,
.jumbotron-3-inner {
	flex-shrink: 0;
	width: 100%;
}

.jumbotron-3-inner {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 40px 0 60px;

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		opacity: .45;
		background: linear-gradient(to right, rgba($black, .3), rgba($black, .6));
	}
}

.jumbotron-3-title {
	line-height: 1.25;
	font-weight: 600;
	max-width: 330px;
}

.jumbotron-3-text > * {
	line-height: 1.66;
}

.jumbotron-3-title + * {
	margin-top: 15px;
}

@media (max-width: 479px) {
	.jumbotron-3-title {
		.svg-wave-line {
			max-width: 42px;
		}
	}
}

@include media-breakpoint-up(md) {
	.jumbotron-3-inner {
		padding-top: 80px;
		padding-bottom: 100px;

		&::after {
			background: linear-gradient(to right, rgba($black, 0), $black);
		}
	}

	.jumbotron-3-title {
		max-width: 400px;
		margin-left: 40px;
	}

	.jumbotron-3-text {
		max-width: 520px;
	}

	.jumbotron-3-title + * {
		margin-top: 25px;
	}
}

@include media-breakpoint-up(lg) {
	.jumbotron-3-inner {
		padding-top: 120px;
		padding-bottom: 165px;
	}
}

@include media-breakpoint-up(xl) {
	.jumbotron-3-title + * {
		margin-top: 35px;
	}
}

@include media-breakpoint-up(xxl) {
	.jumbotron-3-title + * {
		margin-top: 55px;
	}
} 
