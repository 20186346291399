/*
*
* Profile
*/

//
// Table of Contents
//
// Profile Classic
// Profile Light
//

//
// Profile Classic
//
.profile-classic {
  position: relative;
  overflow: hidden;
  max-width: 275px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  text-align: center;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
}

.profile-classic-image {
  width: 100%;
}

.profile-classic-main {
  position: relative;
  padding: 15px 15px 20px;
  background: $white;
  
  &::after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    top: -11px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 11px 11px 8px;
    border-color: transparent transparent $white transparent;

  }
}

.profile-classic-position {
  font-weight: 400;
  font-family: $font-family-base;
  color: rgba($black, .4);
}

.profile-classic-name {
  font-weight: 700;
  font-size: 22px;
  color: $black;
}

.profile-classic-unit {
  display: inline-flex;
  align-items: center;
  letter-spacing: 0.03em;
  font-family: $font-family-base;

  
  a {
    display: inline-block;
    letter-spacing: .02em;
    &, 
    &:active, 
    &:focus {
    	color: $tertiary;
    }
    
    &:hover {
      color: $black;
    }
  }
  
  > * + * {
    margin-left: 11px;
  }
}

* + .profile-classic-name { margin-top: 5px; }
* +  .profile-classic-unit { margin-top: 8px; }

//
// Profile Light
//
.profile-light {
  display: flex;
  align-items: center;
  
  > * + * { margin-left: 20px; }
}

.profile-light-image {
  max-width: 66px;
  border-radius: 50%;
}

.profile-light-position {
  line-height: 1.2;
  font-style: italic;
  color: rgba($black, .4);
}

.profile-light-name {
  font-size: 18px;
  line-height: 1.25;
}

* + .profile-light-name { margin-top: 10px; }

@include media-breakpoint-up(sm) {
  .profile-light {
    > * + * { margin-left: 28px; }
  }
}

@include media-breakpoint-up(md) {
  .profile-light {
    > * + * { margin-left: 35px; }
  }
  
  .profile-light-image {
    max-width: 95px;
  }
  
  .profile-light-name {
    font-size: 22px;
  }
}

@include media-breakpoint-up(xl) {
  .profile-light-position {
    font-size: 18px;
  }
  
  .profile-light-name {
    font-size: 24px;
  } 
}
