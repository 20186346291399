/*
*
* Preloader
*/

//
// Base styles
//
.preloader {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	background: $white;
	transition: .2s all linear;

	&.loaded {
		opacity: 0;
		visibility: hidden;
	}
}

//
// Custom Styles
//

$preloader-color: $primary;
.preloader-inner {
	position: absolute;
	top: -10%;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	height: 0;
	max-width: 250px;
	min-width: 250px;
	min-height: 250px;
	width: 10%;
	padding-bottom: 10%;
}

.preloader-top {
	position: absolute;
	width: 100%;
	height: 75%;
	top: 0;
	overflow: hidden;
}

.preloader-top-sun {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	margin: auto;
	height: 0;
	width: 35%;
	padding-bottom: 35%;
	animation: preloader 3s linear infinite;
	&:before{
		content: '';
		position: absolute;
		display: block;
		top: 50%;
		left: 50%;
		transform: translate(-50% , -50%);
		width: 49px;
		height: 72px;
		background: url('../images/preloader-default.png') no-repeat center;
	}
}

@keyframes preloader {
	0% {
		transform: translateY(200%) rotate(0deg)
	}
	25% {
		transform: translateY(0) rotate(0deg);
		transition-timing-function: ease-out;
	}
	100% {
		transform: translateY(200%) rotate(-180deg);
		transition-timing-function: ease-in;
	}
}

[data-x-mode='true'] .preloader {
	display: none;
}