/**
*
* RD Navbar Modern
*/
.rd-navbar-modern {

  // Static
  &.rd-navbar-static {
    transition: none;
    border-bottom: 1px solid transparent;

    .rd-navbar-main-outer {
      padding-left: $grid-gutter-width / 2;
      padding-right: $grid-gutter-width / 2;
    }

    .rd-navbar-main {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: map-get($container-max-widths, xl) - $grid-gutter-width;
      padding: 20px 0;
      margin-left: auto;
      margin-right: auto;
      min-height: 120px;
    }

    .rd-navbar-nav-wrap {
      width: 100%;
      text-align: right;
      flex-grow: 1;
    }

    .rd-navbar-nav-wrap +  .rd-navbar-collapse-outer {
      margin-left: 40px;
    }

    @include media-breakpoint-up(xl) {
      .rd-navbar-nav-wrap +  .rd-navbar-collapse-outer {
        margin-left: 53px;
      }
    }

    // RD Navbar Brand
    .rd-navbar-brand {
      img {
        max-width: 200px;
        max-height: 200px;
      }
    }

    .rd-navbar-megamenu {
      left: 50%;
      margin-left: -$rd-navbar-megamenu-width / 2;
    }

    @include media-breakpoint-up(xl) {
      .rd-nav-item + .rd-nav-item {
        margin-left: 48px;
      }
    }

    &.rd-navbar--is-stuck,
    &.rd-navbar--is-clone {
      transition: .1s;
      border-bottom-color: nth($rd-navbar-border, 3);
      .rd-navbar-aside-outer {
        display: none;
      }

      .rd-navbar-main {
        padding: 17px 0;
        min-height: 75px;
      }

      .rd-navbar-nav-item {
        > .rd-navbar-submenu {
          margin-top: 17px;
        }
      }
    }
  }

  // Fixed 
  &.rd-navbar-fixed {
    .rd-navbar-placeholder {
      height: 56px;
    }

    .rd-navbar-collapse-toggle .rd-navbar-collapse-toggle-element {
      display: none;
    }
  }

  .rd-navbar-collapse-toggle {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    > * + * {
      margin-top: 8px;
    }

    span {
      display: block;
      height: 2px;
      background: $black;
      transition: .11s;

      &:nth-child(1),
      &:nth-child(3) {
        width: 18px;
      }

      &:nth-child(1) { }

      &:nth-child(2) {
        width: 24px;
      }

      &:nth-child(3) { }
    }

    &.active {
      transform: scale(1.1, 1);
      span {
        width: 24px;
      }
    }
  }
}

.context-dark {
  .rd-navbar-modern {
    &.rd-navbar-static {
      .rd-nav-item > .rd-navbar-submenu-toggle {
        &:hover {
          color: rgba($white, .5);
        }
      }

      .rd-nav-link {
        &::after {
          background: $white;
        }
      }

      .rd-navbar-collapse-toggle {
        span {
          background: $white;
        }
      }

      &.rd-navbar--is-stuck {
        background: $gray-800;
        border-bottom-color: $gray-900;
      }
    }

    &.rd-navbar-fixed {
      .rd-navbar-panel {
        background: $gray-800;
        border-bottom-color: $gray-900;
      }

      .rd-navbar-toggle {
        @include make-toggle(
            $rd-navbar-toggle-preset,
            32px,
            $border-radius,
            2px,
            10px,
            $primary
        );
      }

      .rd-navbar-collapse-toggle {
        span {
          background: rgba($white, .5);
        }
      }

      @include media-breakpoint-down(sm) {
        .rd-navbar-collapse-close {
          transform: scale(.9, .9);
        }
      }
    }
  }
}


.context-dark {
  .rd-navbar-static .rd-nav-link::after {
    background: $primary;
  }
}
