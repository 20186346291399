// Price Block

.price-block {
	border-radius: 10px;
	text-align: center;
	background: $gray-100;
	max-width: 274px;
	margin-right: auto;
	margin-left: auto;
}

.price-block-header {
	padding-top: 37px;
	padding-bottom: 28px;
	display: inline-block;
}

.price-block-body {
	background: $white;
	padding-top: 27px;
	padding-bottom: 49px;
	border-radius: 0 0 10px 10px;
}

.price-list {
	line-height: 26px;
}