/*
*
* Layouts
*/

//
// Table of Contents
//
// Layout 1
// Blog Layout
// Layout 2
// Layout 3
//

//
// Layout 1
//
.layout-1 {
}

@include media-breakpoint-up(lg) {
	.layout-1-aside {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			height: 630px;
			width: 1px;
			max-height: 100%;
		}
	}

	.layout-1-aside-inner {
		max-width: 250px;
		margin-left: auto;
		margin-right: auto;
	}
}

//
// Blog layout
//
.blog-layout {
	> * + * {
		margin-top: 60px;
	}
}

.blog-layout-aside {
	@include groupY(45px);
}

* + .blog-layout-main-item { margin-top: 45px; }

@media (min-width: 480px) and (max-width: map-get($grid-breakpoints, lg) - 1px) {
	.blog-layout-aside {
		columns: 2;
		break-inside: avoid;
		column-gap: 30px;
	}

	.blog-layout-aside-item {
		display: inline-block;
		width: 100%;
	}
}

@include media-breakpoint-up(md) {
	.blog-layout-aside {
		@include groupY(60px);
	}

	* + .blog-layout-main-item { margin-top: 60px; }
}

@include media-breakpoint-up(lg) {
	.blog-layout {
		display: flex;

		> * + * {
			margin-left: 80px;
		}
	}

	.blog-layout-aside {
		width: 250px;
		flex-shrink: 0;
	}
}

@include media-breakpoint-up(xl) {
	.blog-layout {
		> * + * {
			margin-left: 130px;
		}
	}

	.blog-layout-aside {
		width: 270px;
		@include groupY(80px);
	}

	* + .blog-layout-main-item { margin-top: 80px; }
}


//
// Layout 2
//
.layout-2 {
	border: 1px solid transparent;
	@include groupY(15px);
	text-align: center;
}

@media (min-width: 480px) {
  .layout-2 {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		@include groupX(20px);

		> * {
			vertical-align: middle;
		}
	}

	.layout-2-item {
		flex-shrink: 0;
		margin-top: 30px;
	}
}

//
// Layout 3
//
.layout-3 {
	
}

.layout-3-aside {
	max-width: 300px;
}

* + .layout-3-main { margin-top: 30px; }

@include media-breakpoint-down(xs) {
  .layout-3-aside {
		margin-left: auto;
		margin-right: auto;
	} 
}

@include media-breakpoint-up(lg) {
	.layout-3-main {
		[class*='col']:nth-child(odd) {
			padding-top: 6%;
		}
	}
	
	* + .layout-3-main { margin-top: 0; }
}

@include media-breakpoint-up(xl) {
	.layout-3-main {
		[class*='col']:nth-child(odd) {
			padding-top: 9%;
		}
	}

	* + .layout-3-main { margin-top: -30px; }
} 
