/*
* Boxes
*/

//
// Table of Contents
//
// Box 1
// Box 2
// Box 3
// Box 4
// Box Float 1
// Box 5
// Box 6
// Box Inline 1
// Box 7
// Box 8
// Box 9
//

//
// Box 1
//
.box-1 {

	.box-1-title{
		font-size: 22px;
		font-weight: 700;
    .context-light &{
      color: $black;
    }
	}

	.box-1-subtitle{
		color: rgba($white,.7);
		letter-spacing: .02em;
		.context-light &{
			color: rgba($black,.7);
		}
	}
	.box-1-det{
		font-size: 22px;
		color: $white;
		font-weight: 700;
		.context-light &{
			color: $black;
		}
		a{
			@include link($white,$primary);
			.context-light &{
				@include link($black,$primary);
			}
		}
	}
}

.contacts-box{
	@media (max-width: 576px){
		width: 100%;
		text-align: center;
	}
}

.box-1-address {
	letter-spacing: -.02em;
	color: $body-color;
}

.box-1-tel {
	letter-spacing: .03em;
	font-weight: 600;
}

* + .box-1-address { margin-top: 10px; }
* + .box-1-tel { margin-top: 10px; }

@include media-breakpoint-up(md) {
	* + .box-1-address { margin-top: 15px; } 
}

@include media-breakpoint-up(xl) {
	.box-1 {
		padding-right: 0;
	}
} 


//
// Box 2
//
.box-2 {
	position: relative;
	padding: 25px 20px;
	border-radius: 4px;
	text-align: center;
	background: $white;
	box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.25);
	width: 100%;
	textarea.form-input {
		height: 120px;
	}
}

.box-2-inner {
	max-width: 460px;
	margin-left: auto;
	margin-right: auto;
}

@include media-breakpoint-up(xl) {
	.box-2 {
		padding: 61px 30px;
		* + .rd-form { margin-top: 40px; }
		* + .form-wrap {margin-top: 27px; }
		* + .form-wrap-button {margin-top: 55px; }
	}
} 


//
// Box 3
//
.box-3 {
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 30px;
	min-height: 300px;
}

@include media-breakpoint-up(lg) {
  [class*='cell'] {
		.box-3 {
			margin-left: 30px;
		}
	} 
}


a.box-3-play {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 75px;
	height: 75px;
	line-height: 75px;
	background: $white;
	border-radius: 50%;
	
	.icon {
		font-size: 22px;
		line-height: inherit;
		color: $primary;
	}
	
	&::before,
	&::after {
		content: '';
		position: absolute;
		border-radius: 50%;
		border: 1px solid;
    pointer-events: none;
	}
	
	&::before {
		top: -14%;
		left: -14%;
		width: 128%;
		height: 128%;
		border-color: rgba($white, .5);
		transition: .5s;
	}
	
	&::after {
		top: -28%;
		left: -28%;
		width: 158%;
		height: 158%;
		border-color: rgba($white, .3);
		transition: .7s;
	}
	
	&:hover {
		&::before {
			transform: scale(.75);
		}
		
		&::after {
			transform: scale(.6);
		}
	}
}


//
// Box 4
//
.box-4-outer {
	position: relative;
}

.box-4 {
	padding: 25px 20px;
  border-radius: 4px 4px 0 0;
  background: $white;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
	text-align: left;
}

.box-4-toggle {
	@include reset-button;
	min-width: 50px;
	height: 50px;
	padding: 5px 25px;
	line-height: 40px;
	background: $white;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	text-align: center;
	transition: .22s;
	
	> * + * {
		margin-left: 8px;
	}
	
	&.active {
		background: darken($white, 1%);
	}
}

@include media-breakpoint-up(md) {
  .box-4 {
		padding: 35px 30px 45px;
	} 
}

@include media-breakpoint-down(md) {
	.box-4-outer {
		text-align: right;

		.box-4 {
			position: absolute;
			z-index: 4;
			right: 0;
			top: 100%;
			margin-top: 10px;
			opacity: 0;
			visibility: hidden;
			transition: .22s;
			max-width: 100%;
			width: 370px;

			&.active {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

@include media-breakpoint-up(lg) {
	.box-4-toggle {
		display: none;
	}

	.box-4-inner {
		max-width: 280px;
		margin-left: auto;
		margin-right: auto;
	}
}

@include media-breakpoint-up(xl) {
	.box-4 {
		padding-top: 65px;
		padding-bottom: 80px;

		* + .rd-form { margin-top: 45px; }
	}
} 

//
// Box Float
//
.box-float-1 {
	@include media-breakpoint-up(sm) {
		@include clearfix;
		img {
			max-width: 160px;
			margin-right: 25px;
			float: left;
		} 
		
		> * + * {
			margin-top: 0;	
		}
	}
}


//
// Box 5
//
.box-5 {

}

.box-5-aside {
	padding: 15px;
	border-radius: 4px 4px 0 0;
	background: $white;
	box-shadow: 0 0 12px rgba($black, .25);
	text-align: center;
}

.box-5-rating {
	font-size: 30px;
	line-height: 1;
	font-weight: 600;
	color: $primary;
}

.box-5-main {
	.progress-bar-linear-wrap {
		position: relative;
		border-radius: 4px;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			border-radius: 4px;
			background: $white;
			border: 1px solid rgba($gray-300, .5);
		}
	}

	.progress-bar-linear {
		position: relative;
		padding: 10px;
		border-radius: 4px 0 0 4px;
	}

	.progress-title,
	.progress-value {
		font-size: 14px;
		line-height: 1.2;
	}

	.progress-value {
		position: absolute;
		top: 50%;
		right: 9px;
		transform: translate3d(0, -50%, 0);
		&::after {
			display: none;
		}
	}

	.progress-linear {
		.progress-bar-linear-wrap {
			height: auto;
			background-color: transparent;
		}

		.progress-value {
			font-weight: 500;
			color: $white;
		}
	}
}

.box-5-list {
	> li + li { margin-top: 15px; }
}

* + .box-5-title { margin-top: 10px; }
* + .box-5-main { margin-top: 30px; }

@include media-breakpoint-up(sm) {
  .box-5-main {
		.progress-header {
			padding: 3px 8px;
			background: $primary;
		}

		.progress-bar-linear-wrap {
			margin: 0;
			flex-grow: 1;
		}

		.progress-title {
			color: $white;
		}

		.progress-linear {
			display: flex;
			border: 1px solid rgba($gray-300, .5);
			border-radius: 4px;

			.progress-bar-linear-wrap {
				height: auto;
				border: 0;
			}

			.progress-bar-linear {
				height: 100%;
				border-radius: 0;
			}

			.progress-header {
				margin: 0;
				transform: none;

				> * {
					margin-top: 0;
				}
			}
		}
	}
}

@include media-breakpoint-up(md) {
	.box-5 {
		display: flex;
		align-items: flex-start;
	}

	.box-5-aside {
		width: 208px;
		padding: 40px 25px;
		flex-shrink: 0;
	}

	.box-5-main {
		width: 100%;
		flex-grow: 1;
	}

  .box-5-rating {
		font-size: 45px;
	}

	.box-5-list {
		> li + li { margin-top: 21px; }
	}

	* + .box-5-main {
		margin-top: 0;
		margin-left: 24px;
	}

	* + .box-5-title { margin-top: 15px; }
}

@include media-breakpoint-up(xl) {
	.box-5 {
		.progress-title,
		.progress-value {
			font-size: 16px;
		}
	}
}


//
// Box 6
//
.box-6 {
	padding: 20px 20px 30px;
	border-radius: 6px;
	background: $white;
}

* + .box-6 { margin-top: 30px; }

@include media-breakpoint-up(md) {
	.box-6 {
		padding: 33px 42px 40px;
	}

  * + .box-6 { margin-top: 50px; }
}

//
// Box Inline 1
//
.box-inline-1 {
	display: flex;
	align-items: center;
	font-size: 22px;
	@include groupX(8px);

	> * {
		display: inline-block;
	}

	.icon {
		color: rgba($black, .4);
	}

	a {
		letter-spacing: .03em;

		&,
		&:active,
		&:focus {
			color: $primary;
		}

		&:hover {
			color: $secondary;
		}
	}
}

* + .box-inline-1 { margin-top: 11px; }

@include media-breakpoint-up(md) {
	.box-inline-1 {
		font-size: 24px;
		@include groupX(14px);
	}
}

@include media-breakpoint-up(lg) {
  .box-inline-1 {
		font-size: 30px;
	}
}

//
// Box 7
//
.box-7 {
	padding: 25px 20px;
	border-radius: 5px;
	background: $white;
}

* + .box-7 { margin-top: 25px; }

@include media-breakpoint-up(lg) {
  .box-7 {
		max-width: 700px;
		padding: 35px 40px 55px;
		margin-right: 30px;
	} 
} 

//
// Box 8
//
.box-8 {
	padding: 20px;
	background: $white;
  border-radius: 4px;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);

	.list-pricing-title {
		font-weight: 400;
	}
}

@include media-breakpoint-down(xs) {
	.box-8 {
		max-width: 370px;
		margin-left: auto;
		margin-right: auto;
	}
}

@include media-breakpoint-up(md) {
	.box-8 {
    .list-pricing {
      > li + li { margin-top: 14px; }
    }
  } 
}

@include media-breakpoint-up(xl) {
	.box-8 {
		padding: 35px 30px 35px 40px;
	}
} 


//
// Box 9
//
.box-9 {
	padding: 25px 0;
	text-align: center;
	
	> * + * {
		margin-top: 20px;
  }
}

.box-9-group {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	@include spacing(15px);
}

.box-9-item {
	width: (100% / 2);
	padding: 0 10px;
	flex-shrink: 0;
	text-align: center;
}

.box-9-link {
	display: block;

	> * + * {
		margin-top: 4px;
	}
}

.box-9-link-inner {
	position: relative;
	display: inline-flex;
	vertical-align: middle;
	width: 50px;
	border-radius: 50%;
	color: rgba($black, .4);
	border: 1px solid rgba($black, .2);;
	
	&::before {
		content: '';
		width: 100%;
		padding-bottom: 100%;
		border-radius: 50%;
		opacity: 0;
		visibility: hidden;
		margin-left: -100%;
	}
	
	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-radius: 50%;
		opacity: 0;
		visibility: hidden;
		background: $primary;
		transform: scale(1.1);
		transition: .22s;
	}
	
	.icon {
		position: relative;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px 15px;
		font-size: 24px;
		transition: .22s;
		border-radius: 50%;
	}
	
	&:hover {
		border-color: $primary;
		
		&::after {
			opacity: 1;
			visibility: visible;
			transform: scale(1);
		}
		
		.icon {
			color: $white;
		}
	}
}

.box-9-link-inner {
	&::before,
	.icon {
		width: 100%;
		flex-shrink: 0;
	}
}

.box-9-title {
	line-height: 1.45;
	color: $black;
}

@media (min-width: 480px) {
	.box-9-item {
		width: 25%;
	}
}

@include media-breakpoint-up(md) {
	.box-9 {
		display: flex;
		align-items: flex-start;
    justify-content: space-between;
		text-align: left;
		
		> * + * {
			margin-top: 0;
			margin-left: 15px;
		}
	}

	.box-9-aside {
		display: flex;
		align-items: center;

		> * + * {
			margin-left: 20px;
		}
	}

	.box-9-arrow {
		flex-shrink: 0;
	}

	.box-9-group-outer {
		flex-grow: 1;
	}

	.box-9-group {
		justify-content: flex-start;
	}

	.box-9-item {
		width: 25%;
	}

	.box-9-link-inner {
		width: 77px;
	}

	.box-9-link {
		.icon {
			font-size: 30px;
		}
	}
}

@include media-breakpoint-up(lg) {
	.box-9 {
		padding: 40px 0;

		> * + * {
			margin-left: 20px;
		}
	}

	.box-9-item {
		width: 12.5%;
	}

	.box-9-link .icon {
		padding: 10px 23px;
	}

	@include media-breakpoint-down(lg) {
		.box-9-link-inner {
			width: 70px;
		}

		.box-9-title {
			font-size: 15px;
		}
	}
}

@include media-breakpoint-up(xl) {
	.box-9 {
		align-items: center;
		> * + * {
			margin-left: 35px;
		}
	}

	.box-9-group {
		justify-content: space-between;
	}
	 
	.box-9-link {
		.icon {
			font-size: 38px;
		}
	}

	.box-9-title {
		display: none;
	}
}


.label-box{
	position: relative;
	.label-figure{
		position: absolute;
		width: 130px;
		height: 130px;
		border-radius: 50%;
		background-color: $tertiary;
		padding: 12px;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		line-height: 1.2;
		font-weight: 700;
		letter-spacing: .02em;
		font-size: 16px;
		color: $white;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		@media (min-width: 568px){
			bottom: 43px;
			left: -36px;
			transform: none;
		}
		&:before{
			content: '';
			position: absolute;
			display: block;
			pointer-events: none;
			width: 118px;
			height: 118px;
			border-radius: 50%;
			border: 2px solid rgba($white, .5);
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
		}
	}
}