/*
*
* Select 2 v4
*/

// Style variables
$select-color: $form-input-color;
$select-background: $form-input-background;
$select-border: 0;
$select-border-radius: $form-input-border-radius;

$select-padding-y: $form-input-padding-vertical;
$select-padding-x: $form-input-padding-horizontal;

$select-font-size: $form-input-font-size;
$select-line-height: $form-input-line-height;
$select-font-weight: $form-input-font-weight;
$select-height: $form-input-height;


$select-arrow: '\f236';
$select-arrow-color: $select-color;
$select-placeholder-color: $body-color;

// Droppdown
$select-highlight-color: $white; 
$select-highlight-bg: $primary;

$select-selected-color: $white;
$select-selected-bg: lighten($primary, 10%);

$select-disabled-color: $gray-500;
$select-disabled-bg: $gray-100;
$select-search-border: $form-input-border;

.select2 {
  width: 100% !important;
  max-width: 96%;
  // Medium ≥576px
  @include media-breakpoint-up(sm) {
    max-width: 98%;
  }
  // Mega large ≥1600px
  @include media-breakpoint-up(xxl) {
    min-width: 320px;
  }
}

.select2-container {
  box-sizing: border-box;
  top: 6px;
  left: 20px;
  display: inline-block;
  margin: 0;
  position: relative;
  vertical-align: middle;

  @import "select-2_includes/single";
  @import "select-2_includes/multiple";
}

@import "select-2_includes/dropdown";

.select2-close-mask {
  border: 0;
  margin: 0;
  padding: 0;
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  min-height: 100%;
  min-width: 100%;
  height: auto;
  width: auto; 
  opacity: 0;
  z-index: 99;

  // styles required for IE to work

  background-color: $white;
  filter: alpha(opacity=0);
}

.select2-hidden-accessible {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

@import "select-2_includes/themes/default/layout";
