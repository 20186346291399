/*
* Element groups
*/

html {
	.group {
		> * + * {
			margin-top: 0;
		}
	}

	.group-sm {
		@include group(15px, 10px);
	}

	.group-lg {
		@include group(35px, 20px);
	}

	.group-1 {
		@include group(22px, 15px);
	}

	.group-2 {
		@include group(30px, 15px);

		@include media-breakpoint-up(md) {
		  @include groupX(45px);
		}
	}

	.group-3 {
		@include group(20px, 15px);

		@include media-breakpoint-up(lg) {
		  @include groupX(35px);
		}
	}

	.group-middle {
		display: inline-table;
		
		> * {
			vertical-align: middle;
		}
	}
}

* + .group-sm { margin-top: 30px; }
* + .group-lg { margin-top: 30px; }
