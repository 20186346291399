/*
* Backgrounds
*/

.context-dark {
	@extend %context-dark;
}

%context-dark {
	// Logo
	.brand {
		.brand-logo-dark {
			display: none;
		}

		.brand-logo-light {
			display: block;
		}
	}
}

.context-light {
	#{headings()} {
		color: $headings-color;	
	}
	
	// Logo
	.brand {
		.brand-logo-dark {
			display: block;
		}

		.brand-logo-light {
			display: none;
		}
	}
}

/*
* Light Backgrounds
*/
.bg-default {
	@include bg-behaviour($body-bg);
}

.bg-gray-100 {
	@include bg-behaviour($gray-100);
}

.bg-color-gray-100 {
	background: $gray-100;
}

.bg-gray-300 {
	@include bg-behaviour($gray-100);
}

.bg-gray-500 {
	@include bg-behaviour($gray-500);
}

/*
* Dark Backgrounds
*/
.bg-gray-800 {
	@include bg-behaviour($gray-800);
}

.bg-color-gray-800 {
	background: $gray-800;
}

.bg-black {
	@include bg-behaviour($black);
}

/*
* Accent Backgrounds
*/
.bg-primary {
	@include bg-behaviour($primary);
}

.bg-primary-2{
	@include bg-behaviour($primary-2);
}

.bg-secondary {
	@include bg-behaviour($secondary);
}

.bg-tertiary {
	@include bg-behaviour($tertiary);
}

.bg-accent-blue-2{
	@include bg-behaviour($accent-blue-2);
}

.bg-violet{
	@include bg-behaviour($violet);
}

.bg-accent-green{
	@include bg-behaviour($accent-green);
}

.bg-accent-green-2{
	@include bg-behaviour($accent-green-2);
}


/*
* Background Image
*/
.bg-image {
	background-size: cover;
	background-position: center center;
}

// Desktop only
//
html:not(.tablet):not(.mobile) {
	.bg-fixed {
		@include media-breakpoint-up(lg) {
			background-attachment: fixed;
		}
	}
}

// Background Overlays
[class*='bg-overlay'] {
	position: relative;
	
	> * {
		position: relative;
		z-index: 1;
	}
	
	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
}

.bg-overlay-1 {
	&::after {
		opacity: .6;
		background: linear-gradient(to top, rgba($black, 1), rgba($black, .50));
	}
}

.bg-overlay-2 {
	&::after {
		background: linear-gradient(to bottom, rgba($black, .1) 0%, rgba($black, .21));
	}
}

.bg-overlay-3 {
	&::after {
		background: linear-gradient(to bottom, rgba($black, 0) 0%, rgba($black, .2));
	}
}

.bg-img-1{
	background: url('../images/bg-img-1.jpg') no-repeat top;
	background-size: cover;
}

.bg-img-2{
	background: url('../images/bg-img-2.jpg') no-repeat top;
	background-size: cover;
}


.bg-img-3{
	background: url('../images/bg-img-3.jpg') no-repeat top;
	background-size: cover;
}

.bg-img-5{
	background: url('../images/bg-img-5.jpg') no-repeat top;
	background-size: cover;
}

.bg-cover {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
}