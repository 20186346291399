/*
* Offsets
*/

.offset-top-30 {
  margin-top: 30px;
}

.offset-top-30-xl{
  margin-top: 30px;
}

.offset-top-40 {
  margin-top: 40px;
}

.offset-top-40-xxl {
  // Mega large ≥1600px
  @include media-breakpoint-up(xxl) {
    margin-top: 40px;
  }
}

.offset-xl-30{
  margin-top: 40px;
  @include media-breakpoint-up(xl) {
    margin-top: 30px;
  }
}

.offset-top-80 {
  margin-top: 40px;
  // Large ≥992px
  @include media-breakpoint-up(lg) {
    margin-top: 80px;
  }
}

// Elements offset
* + p { margin-top: 14px; }

// Headings
* + h2 { margin-top: 12px; }
* + h3 { margin-top: 12px; }
* + h4 { margin-top: 12px; }
* + h5 { margin-top: 10px; }

// Headings + Paragraph

// Paragraph + Headings

// Tags + Tags
p + p { margin-top: 12px; }
h5 + h5 { margin-top: 10px; }
h5 + h5 { margin-top: 10px; }
img + p { margin-top: 15px; }
h2 + .list-group-2 {
  margin-top: 40px;
  // Medium ≥768px
  @include media-breakpoint-up(md) {
    margin-top: 78px;
  }

}
// Classes
* + .row:not(.offset-top-0) { margin-top: 30px; }

* + .big { margin-top: 20px; }

* + .list-md { margin-top: 20px; }

* + .text-block { margin-top: 30px; }

* + .button { margin-top: 25px; }

* + .button-top-1 { margin-top: 40px; }

// Classes + Tags
.row + h3,
.row + .heading-3 {
  margin-top: 80px;
}

// Classes + Classes
.container + .container {
  margin-top: 60px;

}

.row + .row { margin-top: 35px; }

// Media offsets
@include media-breakpoint-up(lg) {
  * + h2 { margin-top: 20px; }
  * + h5 { margin-top: 20px; }
  * + p { margin-top: 20px; }
  * + .row:not(.offset-top-0) { margin-top: 45px; }
  * + .big { margin-top: 26px; }
  
  h5 + h5 { margin-top: 14px; }

  .heading-7 + .list-md { margin-top: 40px; }
  .heading-7 + .list-palette { margin-top: 40px; }
  h4 + .price-list { margin-top: 14px; }
}

@include media-breakpoint-up(xl) {
  * + h5 { margin-top: 26px; }
  * + .rd-form { margin-top: 30px; }

  p + p { margin-top: 20px; }
  
  .container + .container { margin-top: 80px; }
  .row + .row { margin-top: 60px; }
}



// Range spacing
.row-0 {
  @include grid-offset(0px);
}

.row-6 {
  @include grid-offset(6px);
}

.row-10 {
  @include grid-offset(10px);
}

.row-10-lg {
  // Large ≥992px
  @include media-breakpoint-up(lg) {
    @include grid-offset(10px);
  }
}

.row-13 {
  @include grid-offset(13px);
}

.row-20 {
  @include grid-offset(20px);
}

.row-30 {
  @include grid-offset(30px);
}

.row-xl-30{
  @include grid-offset(40px);
  @include media-breakpoint-up(xl) {
    @include grid-offset(30px);
  }
}

.row-40 {
  @include grid-offset(40px);
}

.row-xl-45 {
  @include grid-offset(30px);
}

// Extra large ≥1200px
@include media-breakpoint-up(xl) {
  .row-xl-45 {
    @include grid-offset(45px);
  }
}



.row-50 {
  @include grid-offset(50px);
}

.row-xl-50{
  @include grid-offset(30px);
}

@include media-breakpoint-up(xl) {
  .row-xl-50{
    @include grid-offset(50px);
  }
}


.row-lg-50 {
  // Large ≥992px
  @include media-breakpoint-up(lg) {
    @include grid-offset(50px);
  }
}


@include media-breakpoint-up(lg) {
  .row-md-30 {
    @include grid-offset(30px);
  } 
  .row-md-50 {
    @include grid-offset(50px);
  } 
}

@include media-breakpoint-up(xl) {
  .row-xl-40 {
    @include grid-offset(40px);
  }
  .row-xl-50 {
    @include grid-offset(50px);
  }
}

@include media-breakpoint-up(xxl) {
  .row-xxl-80 {
    @include grid-offset(80px);
  }  
}

.offset-xl-60{
  margin-top: 10px;
}

@include media-breakpoint-up(xl) {
  .offset-xl-60{
    margin-top: 60px;
  }
}

.inset-bottom-6{
  padding-bottom: 8px;
}

// Custom offset
$mt-list: 0, 10, 15, 20, 30, 40,50,60;
$mt-md-list: 20, 30, 35, 45, 60;
$mt-lg-list: 40, 50, 60, 80;
$mt-xl-list: 30, 40, 50, 55, 60, 70, 80;
$mt-xxl-list: 50, 70, 75, 85;

.page { 
  @each $mt in $mt-list {
    * + .mt-#{$mt} {
      margin-top: $mt + px;
    }
  }

  @include media-breakpoint-up(md) {
    @each $mt in $mt-md-list {
      * + .mt-md-#{$mt} {
        margin-top: $mt + px;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    @each $mt in $mt-lg-list {
      * + .mt-lg-#{$mt} {
        margin-top: $mt + px;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    @each $mt in $mt-xl-list {
      * + .mt-xl-#{$mt} {
        margin-top: $mt + px;
      }
    }
  }

  @include media-breakpoint-up(xxl) {
    @each $mt in $mt-xxl-list {
      * + .mt-xxl-#{$mt} {
        margin-top: $mt + px;
      } 
    }
  }
}

@include media-breakpoint-up(xl) {
  .offset-top-55{
    margin-top: 55px;
  }
}

// Custom Insets
.inset-1 {
  @include media-breakpoint-up(xl) {
    padding-right: 50px;
  }
}

.inset-2 {
  @include media-breakpoint-up(xl) {
    padding-left: 50px;
  }
}

.inset-3 {
  @include media-breakpoint-up(lg) {
    padding-top: 12px; 
  }
}

.inset-4 {
  @include media-breakpoint-up(lg) {
    padding-right: 20px; 
  }
}

.inset-right-6{
  padding-right: 6px;
}


.custom-padding {
  // Extra large ≥1200px
  @include media-breakpoint-up(xl) {
    > .col,
    > [class*="col-"] {
      padding-right: 5px;
      padding-left: 5px;
    }
  }
}

.custom-padding-2 {
  > .col,
  > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
  }
}
