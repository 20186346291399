/*
*
* Comments
*/

//
// Table of Contents
//
// Review
// Comment Review
//


//
// Review
//
.review-meta {
	@include groupY(10px);
	> li {
		display: flex;
		align-items: center;

		> *  {
			margin-right: 14px;
		}

		> *:last-child {
			margin-right: 0;
		}
	}

	p {
		font-size: 14px;
		font-weight: 500;
		color: $black;
	}
}

.review-rating {
	display: flex;
	align-items: center;
	padding: 2px 0;

	> li {
		display: flex;
		align-items: flex-end;
	}

	> li + li { margin-left: 4px; }

	.icon {
		font-size: 14px;
		line-height: 1;
		color: $gray-300;
	}
}

@include media-breakpoint-up(sm) {
	.review-meta {
		display: flex;
		flex-wrap: wrap;
		margin-left: -15px;
		margin-right: -15px;
		> li {
			flex-basis: 50%;
			padding: 0 15px;
		}
	}
}

@include media-breakpoint-up(md) {
	.review-meta {
		@include groupY(14px);
	}

}

@include media-breakpoint-up(xl) {
	.review-meta {
		@include groupY(22px);

		> li {
			> * {
				margin-right: 17px;
			}
		}

		p {
			font-size: 16px;
		}
	}

	.review-rating {
		.icon {
			font-size: 16px;
		}



		> li + li { margin-left: 8px; }
	}
}

//
// Comment Review
//
.comment-review-group {
	border-top: 1px solid rgba($gray-300, .5);
	border-bottom: 1px solid rgba($gray-300, .5);

	.comment-review {
		padding: 30px 0;
	}

	.comment-review +	.comment-review {
		border-top: 1px solid rgba($gray-300, .5);
	}
}

.comment-review { }

.comment-review-avatar {
	max-width: 43px;
	border-radius: 50%;
}

.comment-review-time {
	display: block;
	color: $gray-500;
}

.comment-review-text {
	color: $gray-500;
}

* + .comment-review-main { margin-top: 20px; }
* + .comment-review-group { margin-top: 30px; }
* + .comment-review-text { margin-top: 10px; }
* + .comment-review-meta { margin-top: 15px; }

@include media-breakpoint-up(md) {
	.comment-review-group {
		.comment-review {
			padding: 49px 0;
		}
	}

	.comment-review {
		display: flex;
		align-items: flex-start;

		> * + * {
			margin-left: 30px;
		}
	}

	.comment-review-avatar {
		max-width: 86px;
		flex-shrink: 0;
	}

	* + .comment-review-main { margin-top: 0; }
	* + .comment-review-group { margin-top: 58px; }
	* + .comment-review-text { margin-top: 20px; }
	* + .comment-review-meta { margin-top: 33px; }
}