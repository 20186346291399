/*
* Sections
*/

//
// Section Spacing
// 
.section-xs {
	padding: 25px 0;
}

.section-sm,
.section-md,
.section-lg,
.section-xl,
.section-1 {
	padding: 50px 0;
}

// Section collapse
.section-collapse + .section-collapse {
	padding-top: 0;
}

.section-collapse:last-child {
	padding-bottom: 0;
}

// Section with bottom zero padding
html [class*='section-'].section-bottom-0 {
	padding-bottom: 0;
}

@include media-breakpoint-up(md) {
	.section-sm {
		padding: 60px 0;
	}

	.section-md {
		padding: 85px 0;

		&.section-first { padding-top: 120px; }
		&.section-last { padding-bottom: 100px; }
	}

	.section-lg {
		padding: 100px 0;
	}

	.section-xl {
		padding: 110px 0;
	}

	.section-1 {
		padding: 60px 0 80px;
	}
}

@include media-breakpoint-up(xl) {
	.section-sm {
		padding: 70px 0 85px;
	}
	
	.section-md {
		padding: 105px 0;

		&.section-first { padding-top: 140px; }
		&.section-last { padding-bottom: 140px; }
	}

	.section-lg {
		padding: 130px 0;
	}
	
	.section-xl {
		padding: 165px 0 175px;
	}

	.section-1 {
		padding: 75px 0 120px;
	}
}

@include media-breakpoint-up(xxl) {
	.section-1 {
		padding: 90px 0 150px;
	}

	.section-md.section-md-1 {
		padding-bottom: 150px;	
	}
}


//
// Custom sections
//

// Section single
.section-single {
	display: flex;
}

.section-single-inner {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-height: 100vh;
}

.section-single-left,
.section-single-right {
	width: 100%;
}

.section-single-left {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 0 15px;
}

.section-single-header {
	padding: calc(1em + 3vh) 0 calc(1em + 2vh);
}

.section-single-main {
	padding: calc(1em + 4vh) 0;
}

.section-single-footer {
	padding: calc(1em + 2vh) 0 50px;
}

.section-single-header,
.section-single-main,
.section-single-footer {

}

.section-single-header{
	.brand{
		max-width: 188px;
	}
}

@include media-breakpoint-up(sm) {
	.section-single-left {
		max-width: 660px;
		padding: 0 30px;
		margin-left: auto;
		margin-right: auto;
	} 
}

@include media-breakpoint-up(md) {
	.section-single-footer {
		padding-bottom: 80px;
	}
}

@include media-breakpoint-up(lg) {
  .section-single-inner {
		align-items: stretch;
		flex-direction: row;
	}
	
	.section-single-right {
		display: flex;
		
		.thumbnail-1 {
			width: 100%;
		}
	}
}

@include media-breakpoint-up(lg) {
	.section-single-left,
	.section-single-right {
		width: 50%;
	} 
}

@include media-breakpoint-up(xxl) {
	.section-single-left {
		padding-right: 100px;
		max-width: 760px;
	}
}

.section-single-2 {
	// Extra large ≥1200px
	@include media-breakpoint-up(xl) {
		padding-bottom: 10px;
	}
}

.section-single-3 {
	padding: 10px 0;
}


//
// Section Decoration 1
//
.section-decoration-1 {
	overflow: hidden;
}

.section-decoration-1-figure {
	position: relative;
	
	> * { 
		position: relative;
		z-index: 1;
	}
}

.section-decoration-1-image {
	position: absolute;
	z-index: 0;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	max-width: none;
	pointer-events: none;
}


//
// Section Decoration 2
//
.section-decoration-2 {
	position: relative;
	overflow: hidden;
	
	> * {
		position: relative;
		z-index: 1;
	}
}

.section-decoration-2-image {
	display: none;
}

@include media-breakpoint-up(xl) {
	img.section-decoration-2-image {
		z-index: 0;
	}

	.section-decoration-2-image {
		position: absolute;
		top: 28%;
		left: 78%;
		display: block;
		transform: translate3d(0, -28%, 0);
		opacity: .2;
	}
}

@include media-breakpoint-up(xxl) {
	.section-decoration-2-image {
		opacity: .3;
		left: calc(50% + #{map-get($container-max-widths, xl) / 2});
	} 
} 

//
// Section Custom 1
//
.section-custom-1 {
	padding: 50px 0 60px;	
}

.section-custom-1-block {
	.form-wrap-icon .form-input, 
	.form-wrap-icon .form-label {
		padding-left: 35px;	
	}

	* + .form-wrap { margin-top: 27px; }
	* + .rd-form { margin-top: 15px; }
}

@include media-breakpoint-up(lg) {
	.section-custom-1 {
		padding: 70px 0 130px;
	}

	.section-custom-1-block {
		position: relative;
		z-index: 1;
		padding: 25px 45px;
		margin-left: -30px;
		background: $white;
		border-radius: 4px 4px 0 0;
	}
	
	.section-custom-1-ally + .section-custom-1 .section-custom-1-block {
		margin-top: -140px;
	} 
}

@include media-breakpoint-up(xl) {
	
}

.section-main {
	position: relative;
	margin-bottom: 160px;
	@include media-breakpoint-up(sm) {
		margin-bottom: 80px;
	}
	@include media-breakpoint-up(lg) {
		margin-bottom: 0;
	}
}