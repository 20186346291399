/*
* Dividers
*/

hr {
	margin-top: 0;
	margin-bottom: 0;
	border-top: $hr-border-width solid $hr-border-color;
}

.divider {
	font-size: 0;
	line-height: 0;

	&::after {
		content: '';
		display: inline-block;
		width: 60px;
		height: 2px;
		background-color: $primary;
	}
}

.divider-small {
	&::after {
		height: 1px;
		width: 165px;
		background: rgba($black, .3);
	}
}

* + .divider-small { margin-top: 20px; }
.divider-small + h4 { margin-top: 20px; }

@include media-breakpoint-up(md) {
	* + .divider-small { margin-top: 35px; }
	.divider-small + h4 { margin-top: 35px; }
}

@include media-breakpoint-up(xl) {
	* + .divider-small { margin-top: 50px; }
	.divider-small + h4 { margin-top: 50px; }

}

// Divider 1
.divider-1 {
	&::after {
		height: 1px;
		width: 55px;
		background: $gray-300;
	}
}

* + .divider-1 { margin-top: 20px; }

@include media-breakpoint-up(md) {
	* + .divider-1 { margin-top: 40px; }
}

// Divider 2
.divider-2 {
	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 1px;
		border-bottom: 1px solid rgba($gray-300, .75);
	}
}

// Divider 3
.divider-3 {
	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 1px;
		border-bottom: 1px solid rgba($black, .1);
	}
}

* + .divider-3 { margin-top: 30px; }
