/*
* Footers
*/

//
// Table of Contents
//
// Footer Classic
// Footer Modern
//

//
// Footer Classic
//
.footer-classic {
  .list {
    font-size: 14px;
    font-weight: 400;
    color: $gray-500;
    
    a {
      &:hover {
        color: $primary;
      }
    }
  }
  .list-sm{
    li + li{
      margin-top: 0;
    }
  }
  .context-dark {
    .list {
      color: $white;
    }

    a {
      color: $white;
    }

    li a:hover {
      color: $white;
      text-decoration: underline;
    }
  }
}

.footer-classic-main {
  padding: 50px 0;
}

.footer-classic-layout {
  @include groupY(45px);
}

.footer-classic-item-block-3 { max-width: 240px; }

.footer-classic-title {
  font-family: $font-family-base;
  font-weight: 700;
  letter-spacing: normal;
  font-size: 22px;
}

.footer-classic-aside {
  padding: 35px 0;
  text-align: center;
}

* + .footer-classic-item-block { margin-top: 15px; }

@include media-breakpoint-down(xs) {
  .footer-classic-layout-item {
    max-width: 370px;
    margin-left: auto;
    margin-right: auto;
  }
}

@include media-breakpoint-down(md) {
  .footer-classic-title {
    font-size: 17px;
  } 
}

@include media-breakpoint-up(sm) {
  .footer-classic-layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include spacingX(20px);
  }
}

@include media-breakpoint-up(md) {
  .footer-classic-main {
    padding: 80px 0;
  }

  .footer-classic-aside {
    padding: 45px 0;
  }
  
  * + .footer-classic-item-block { margin-top: 25px; }
}

@include media-breakpoint-up(lg) {
  .footer-classic {
    .list {
      font-size: 16px;
    }
  } 
}

@include media-breakpoint-up(xl) {  
  .footer-classic-main {
    padding: 105px 0 140px;
  }

  .footer-classic-md {
    .footer-classic-main {
      padding-bottom: 80px;
    }  
  }

  .footer-classic-item-block-1 { min-width: 420px; }
  .footer-classic-item-block-2 { min-width: 420px; }
  
  .footer-classic-aside {
    padding: 59px 0;
  }

  * + .footer-classic-item-block { margin-top: 35px; }
} 


//
// Footer Modern
//
.footer-modern {
  a {
    &, 
    &:active, 
    &:focus {
    	color: $gray-500;
    }
    
    &:hover {
      color: $primary;
    }  
  }
}

.footer-modern-main {
  padding: 35px 0;
}

.footer-modern-title {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: .1em;
  text-transform: uppercase;
}

.footer-modern-aside {
  padding: 20px 0 35px;
  text-align: center;
}

.footer-modern-aside-layout {
  @include groupY(20px);
  
  > * {
    margin-top: 0;
  }
}

* + .footer-modern-item-block { margin-top: 16px; }

@include media-breakpoint-down(xs) {
  .footer-modern {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  } 
}

@include media-breakpoint-up(md) {
  .footer-modern-main {
    padding: 65px 0 40px;  
  }

  @include media-breakpoint-down(md) {
    .footer-classic-md {
      .footer-classic-main {
        max-width: 520px;
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  
  * + .footer-modern-item-block { margin-top: 25px; } 
}

@include media-breakpoint-up(lg) {
  .footer-modern-aside-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    @include groupX(20px);
  }

  .footer-modern-title {
    font-size: 16px;
  }
}

@include media-breakpoint-up(xl) {
  .footer-modern {
    padding-bottom: 80px;  
  }
  
  .footer-modern-main {
    padding: 95px 0 65px;  
  }

  .footer-modern-aside {
    padding: 30px 0;
    .rights {
      font-size: 16px;
    }
  }
  
  .footer-modern-title {
    font-size: 18px;
  }

  * + .footer-modern-item-block { margin-top: 35px; }
} 
