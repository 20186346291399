/**
*
* RD Navbar Classic
*/
$transition-default: .2s all ease-out;
$transition-stuck: .3s;

.rd-navbar-classic {
	.rd-navbar-placeholder {
		position: absolute;
		opacity: 0; 
		visibility: hidden;
		pointer-events: none;
	}
	
	// Static
	&.rd-navbar-static {
		min-height: 120px;
		background-color: transparent;

		.rd-navbar-placeholder {
			height: 75px;
		}

		.rd-navbar-main-outer {
			position: relative;
			padding-left: $grid-gutter-width / 2;
			padding-right: $grid-gutter-width / 2;
			border-bottom: 1px solid transparent;
			will-change: transform;
			backface-visibility: hidden;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: $white;
				opacity: 0;
				transition: $transition-default;
				pointer-events: none;
			}
		}

		.rd-navbar-main {
			position: relative;
			z-index: 1;
			display: flex;
			align-items: center;
			justify-content: space-between;
			max-width: 1600px;
			padding: 40px 0;
			margin-left: auto;
			margin-right: auto;
			transition: $transition-default;
			[data-x-mode='true'] & {
				pointer-events: none;
			}
		}

		// RD Navbar Brand
		.rd-navbar-brand {
			[data-x-mode='true'] & {
				pointer-events: auto;
			}
			img {
				max-width: 200px;
				max-height: 200px;
			}
		}

		.rd-navbar-nav-wrap {
			[data-x-mode='true'] & {
				pointer-events: auto;
			}
		}

		.rd-navbar-collapse-outer {
			[data-x-mode='true'] & {
				pointer-events: auto;
			}
		}
		
		.rd-navbar-megamenu {
			left: 50%;
			margin-left: -$rd-navbar-megamenu-width / 2;
		}

		&.rd-navbar--is-stuck,
		&.rd-navbar--is-clone {
			pointer-events: none;
			.rd-navbar-main-outer {
				transform: translate3d(0, -22px, 0);
				background: $white;
				border-bottom-color: nth($rd-navbar-border, 3);
				transition: $transition-stuck;
				pointer-events: auto;
				&::after {
					opacity: .8;
					transition: inherit;
					background: $white;
				}
			}

			.rd-navbar-aside-outer {
				display: none;
			}

			.rd-navbar-main {
				padding-bottom: 18px;
				transition: $transition-default;
			}

			.rd-navbar-nav-item {
				> .rd-navbar-submenu {
					margin-top: 17px;
				}
			}
		}
	}

	// Fixed 
	&.rd-navbar-fixed {
		.rd-navbar-placeholder {
			height: 56px;
		}

		.rd-navbar-collapse-toggle .rd-navbar-collapse-toggle-element {
			display: none;
		}
	}
}

.context-dark {
	.rd-navbar-classic {
		&.rd-navbar-static {
			.rd-nav-item > .rd-navbar-submenu-toggle {
				&:hover {
					color: rgba($white, .5);
				}
			}

			.rd-navbar-main-outer {
				&::after {
					background: $gray-800;
				}
			}

			&.rd-navbar--is-clone,
			&.rd-navbar--is-stuck {
				.rd-navbar-main-outer {
					background: $gray-800;
					border-bottom-color: $gray-900;
				}
			}
		}
		
		&.rd-navbar-fixed {
			.rd-navbar-panel {
				background: $gray-800;
				border-bottom-color: $gray-900;
			}

			.rd-navbar-toggle {
				@include make-toggle(
						$rd-navbar-toggle-preset,
						32px,
						$border-radius,
						2px,
						10px,
						$white
				);
			}
			
			@include media-breakpoint-down(sm) {
				.rd-navbar-collapse-close {
					transform: scale(.9, .9);
				}
			}
		}
	}
}

