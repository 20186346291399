//
// Nav custom
// 

// 
// Table of Contents:
// 
// Tabs Complex
//

// Base styles
.tabs-custom {
	text-align: left;
	.nav-tabs {
		font-size: 0;
		line-height: 0;
		word-spacing: 0;
		border: 0;

		&:before,
		&:after {
			display: none;
		}
	}

	.nav-item {
		float: none;
		border: 0;
		cursor: pointer;
		transition: .33s all ease;
	}

	.nav-link {
		margin: 0;
		border-radius: 0;
		border: 0;
	}

	.nav-link.active {
		cursor: default;
		border: 0;
	}
}

.tab-content > .tab-pane {
	display: block;
	visibility: hidden;
	height: 0;
	overflow: hidden;
}

.tab-content > .active {
	visibility: visible;
	height: auto;
	overflow: visible;
}

//
// Tabs Complex
//
.tabs-complex {
	.nav-tabs {
		margin: -1px;
	}
	
	.nav-item {
		display: flex;
		border: 1px solid $gray-300;
		border-bottom: 0;
		margin: -1px;
	}
	
	.nav-link {
    display: flex;
    align-items: center;
		justify-content: center;
		width: 100%;
		padding: 12px 10px;
		font-size: 13px;
		letter-spacing: .05em;
		background: $white;
		color: $gray-500;
		&:hover {
			color: $primary;
		}
		
		span {
			font-size: 18px;
			@include media-breakpoint-up(xl) {
				font-size: 22px;
			}
			line-height: 1.2;
		}
		
		span + span {
			margin-left: 8px;
		}
	}
	
	.nav-link.active {
		color: $black;
		background: $gray-100;
	}
	
	.tab-content {
		background: $gray-100;
	}
	
	.tab-pane {
		padding: 30px 0 60px;
	}
	
	.tab-pane:not(.show) {
		padding: 0;
	}
}

.tabs-complex-nav-toggle {
	@include reset-button;
	width: 100%;
	padding: 10px 30px;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	letter-spacing: .05em;
	text-align: center;
	
	> * + * {
		margin-left: 5px;
	}
}

@include media-breakpoint-down(sm) {
  .tabs-complex {
		.nav-tabs {
			position: absolute;
			z-index: 1;
			left: 50%;
			transform: translate3d(-50%, 0, 0);
			opacity: 0;
			width: 100%;
			visibility: hidden;
			transition: .22s;
			border-bottom: 1px solid $gray-300;
			
			&.active {
				opacity: 1;
				visibility: visible;
			}
		}
		
		.nav-item {
			width: 100%;
			margin: 0;
			border-width: 1px 0 0 0;
		}
	} 
}

@include media-breakpoint-up(md) {
	.tabs-complex-nav-toggle {
		display: none;
	}
	
	.tabs-complex {
		.nav-tabs {
			display: flex;
			flex-wrap: wrap;
		}
		
		.nav-item {
			flex-grow: 1;
		}
		
		.nav-link {
			padding: 18px 10px;
		}
		
		.tab-pane {
			padding: 75px 0;
		}
	}
}
@include media-breakpoint-up(lg) {
  .tabs-complex {
		.nav-link {
			font-size: 16px;
		}
	} 
}

@include media-breakpoint-up(xl) {
  .tabs-complex {
		.nav-link {
			padding: 28px 30px;
			font-size: 18px;
		}
		
		.tab-pane {
			padding: 100px 0;
		}
	} 
} 

@include media-breakpoint-up(xxl) {
  .tabs-complex {
		.nav-link {
			min-height: 105px;
		}
	} 
} 
