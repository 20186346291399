/*
* Thumbnails
*/

// 
// Table of Contents
// 
// Figure Light
// Thumbnail 1
// Thumbnail Light
//

//
// Figure Light
//
.figure-light figcaption {
	padding-top: 10px;
	color: $gray-400;
}

// Figure 1
.figure-1 {
	@include media-breakpoint-up(xl) {
    position: relative;
    left: -20px;
		margin-left: -30px;
	}
}

.thumbnail-1 {
	position: relative;
	display: flex;
	width: 100%;
	min-height: 300px;
	background: $gray-100;
	
	&::after {
		content: '';
		display: block;
		width: 100%;
		padding-bottom: (9 / 16) * 100%;
		opacity: 0;
		visibility: hidden;
	}
	
	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: linear-gradient(rgba(196, 196, 196, 0) 0%, $black 100%);
		opacity: .7;
	}
	
	&.video-playing {
		.thumbnail-1-play .icon {
			&::before {
				opacity: 0;
				visibility: hidden;
			}
			
			&::after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

.thumbnail-1::after,
.thumbnail-1-inner {
	width: 100%;
	flex-shrink: 0;
}

.thumbnail-1-inner {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 30px;
}

.thumbnail-1-play {
	position: relative;
	z-index: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 75px;
	height: 75px;
	line-height: 75px;
	border-radius: 50%;
	border: 5px solid rgba($white, .3);

	.icon {
		position: relative;
		font-size: 28px;
		line-height: inherit;
		color: $white;
		
		&::before,
		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate3d(-50%, -50%, 0);
			font-family: 'Material Design Icons';
			transition: .22s;
		}
		
		&::before {
			content: '\f4f9';
		}
		
		&::after {
			content: '\f4d3';
			opacity: 0;
			visibility: hidden;
		}
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		border-radius: 50%;
		pointer-events: none;
		margin-right: -1px;
		margin-bottom: -1px;
	}

	&::before {
		top: -20%;
		left: -20%;
		width: 140%;
		height: 140%;
		border: 3px solid rgba($white, .3);
		transition: .5s;
	}

	&::after {
		top: -30%;
		left: -30%;
		width: 160%;
		height: 160%;
		border: 2px solid rgba($white, .3);
		transition: .7s;
	}
}

@include media-breakpoint-up(lg) {
	.thumbnail-1-play {
		width: 126px;
		height: 126px;
		line-height: 126px;

		.icon {
			font-size: 36px;
		}

		&::before {
			top: -12%;
			left: -12%;
			width: 124%;
			height: 124%;
		}

		&::after {
			top: -20%;
			left: -20%;
			width: 140%;
			height: 140%;
		}
	} 
	
	@include media-breakpoint-up(lg) {
	  .thumbnail-1-play {
			&:hover {
				&::before,
				&::after {
					opacity: 0;
				}

				&::before {
					transform: scale(.9);
				}

				&::after {
					transform: scale(.8);
				}
			}
		} 
	}
}


//
// Thumbnail Light
//
.thumbnail-light {
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	background: $black;

	&::after {
		content: '';
		display: block;
		padding-bottom: (355 / 359) * 100%;
		opacity: 0;
		visibility: hidden;
	}

	&::before {
		position: relative;
		z-index: 1;
		content: '\ec22';
		font-family: 'Linearicons';
		font-size: 20px;
		color: $white;
	}
}

.thumbnail-light-image {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	display: block;
	min-height: 100%;
	min-width: 100%;
	width: auto;
	height: auto;
	max-width: none;
	opacity: .8;


	@supports (object-fit: cover) {
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		transform: none;
		object-fit: cover;
		object-position: center center;
	}
}

@include media-breakpoint-up(sm) {
	.thumbnail-light {
		&::before {
			font-size: 30px;
		}
	}
}

@include media-breakpoint-up(lg) {
	html:not(.tablet):not(.mobile) {
		.thumbnail-light {
			&::before {
				opacity: 0;
				visibility: hidden;
				transition: .44s;
			}

			&:hover {
				&::before {
					opacity: 1;
					visibility: visible;
				}

				.thumbnail-light-image {
					opacity: .8;
				}
			}
		}

		.thumbnail-light-image {
			opacity: 1;
			transition: .33s;
		}
	}
}


//
// Thumbnail Classic
//

//
// Thumbnail Classic
//

.thumbnail-classic {
	position: relative;
	text-align: center;
}

.thumbnail-classic-figure {
	display: block;

	img {
		width: 100%;
	}
}

.thumbnail-classic-caption {
	padding: 15px;
	background: linear-gradient(180deg, #FF7A50 0%, #FFE072 100%);
}

.thumbnail-classic-title {
	font-weight: 700;
	letter-spacing: -0.02em;
	color: $white;
}

.thumbnail-classic-subtitle {
	color: $white;
	font-family: $font-family-base;
	font-weight: 400;
	line-height: 27px;
	max-width: 300px;
	margin-right: auto;
	margin-left: auto;
}


.thumbnail-classic-button {
	display: inline-block;
	vertical-align: middle;
	font-size: 0;
	line-height: 0;



	.button {
		text-align: center;
		padding: 0;
		width: 53px;
		height: 53px;
		font-size: 28px;
		font-weight: 400;
		line-height: 54px;
		border-radius: 50%;
		color: $black;
		background: $white;
		border: none;
		position: relative;
		&::before {
			content: '\f43b';
			font-family: "Material Design Icons";
		}
	}



	img {	display: none; }
}

.thumbnail-classic-button + .thumbnail-classic-button { margin-left: 16px; }

* + .thumbnail-classic-button-wrap { margin-top: 15px; }


.desktop {
	// Large ≥992px
	@include media-breakpoint-up(lg) {

		.thumbnail-classic {
			overflow: hidden;
		}

		.thumbnail-classic-caption {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: transparent;
			pointer-events: none;
			z-index: 1;

			&::before {
				position: absolute;
				content: '';
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				background: linear-gradient(180deg, #FF7A50 0%, #FFE072 100%);
				transform: scale(.8);
				transition: all .3s ease;
				opacity: 0;
				z-index: -1;
			}

			> div {
				pointer-events: auto;
			}
		}

		.thumbnail-classic-title,
		.thumbnail-classic-subtitle {
			transform: translate3d(0, -30px, 0);
			transition: all .4s ease;
			opacity: 0;
		}



		.thumbnail-classic-button {
			transition: all .4s ease;
			opacity: 0;

			&:nth-child(1) {
				transform: translate3d(-40px, 40px, 0);
			}

			&:nth-child(2) {
				transform: translate3d(40px, 40px, 0);
			}
		}

		.thumbnail-classic:hover {

			.thumbnail-classic-caption::before,
			.thumbnail-classic-subtitle,
			.thumbnail-classic-title,
			.thumbnail-classic-button {
				transform: none;
				opacity: 1;
			}
			.thumbnail-classic-caption::before {
				opacity: .8;
			}
		}

		* + .thumbnail-classic-button-wrap { margin-top: 16px; }

		.thumbnail-classic-title + .thumbnail-classic-subtitle {
			margin-top: 16px;
		}
	}
}








.gallery-thumnail{
	max-width: 362px;
	margin-right: auto;
	margin-left: auto;
	position: relative;
	overflow: hidden;
	border-radius: 8px;
	&:before{
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba($dark-blue,.7);
		z-index: 1;
		opacity: 1;
		@include media-breakpoint-up(xl) {
			opacity: 0;
		}
		pointer-events: none;
		transition: all ease .25s;
	}

	a{
		position: relative;
		display: block;
		&:before{
			content: '\f43b';
			font-family: $mdi;
			font-size: 30px;
			line-height: 1;
			color: $white;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			transition: all ease .25s;
			opacity: 1;
			@include media-breakpoint-up(xl) {
				opacity: 0;
			}
			z-index: 2;
		}
		&:after{
			content: '';
			position: absolute;
			width: 67px;
			height: 67px;
			left: 50%;
			top: 50%;
			transform: translate(-50%,-50%);
			background-color: $primary;
			transition: all ease .25s;
			opacity: 1;
			@include media-breakpoint-up(xl) {
				opacity: 0;
			}
			border-radius: 50%;
			z-index: 1;
		}
		img{
			transition: all ease .25s;
		}
	}
	&:hover{
		&:before{
			opacity: 1;
			transition: all ease .25s;
		}
		a{
			&:before,&:after{
				opacity: 1;
				transition: all ease .25s;
			}
			img{
				transform: scale(1.08);
				transition: all ease .25s;
			}
		}
	}
}



.gallery-thumnail-1{
	margin-top: 20px;
	margin-right: auto;
	margin-left: auto;
	position: relative;
	overflow: hidden;
	background: none;
	border: none;
	transition: .22s;
	max-width: 157px;
	img {
		display: block;
	}
	&:hover {
		box-shadow: 0 4px 10px 0 rgba($black, .3);
	}
	&.active {
		box-shadow: 0 8px 20px 0 rgba($black, .35);
	}
}