/*
* Quotes
*/

//
// Table of Contents: 
//
// Quote Primary
// Quote Classic
// Quote Light
//

//
// Quote primary
//
.quote-primary {
}

.quote-primary-text {
	font-style: italic;
	font-weight: 300;
	font-size: 16px;
	line-height: (41 / 24);
	letter-spacing: .02em;
	color: $body-color;
}

.quote-primary-line {
	stroke: $primary;
}

.quote-primary-footer {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	@include group(25px, 5px);
}

* + .quote-primary { margin-top: 25px; }
* + .quote-primary-footer { margin-top: 8px; }

@include media-breakpoint-up(md) {
	.quote-primary-text {
		font-size: 20px;
	}

	* + .quote-primary { margin-top: 30px; }
	* + .quote-primary-footer { margin-top: 15px; }
}

@include media-breakpoint-up(lg) {
	.quote-primary-text {
		font-size: 22px;
	} 
}

@include media-breakpoint-up(xl) {
	.quote-primary-text {
		font-size: 24px;
	}

	* + .quote-primary { margin-top: 45px; }
  * + .quote-primary-footer { margin-top: 22px; }
} 


//
// Quote Classic
//
.quote-classic {
	padding: 24px 15px;
	background: $white;
	border-radius: 10px;
	text-align: left;
	box-shadow: 0 9px 28px 0 rgba($black, 0.2);
}

.quote-classic-inner {
	max-width: 380px;
	margin-left: auto;
	margin-right: auto;
}

.quote-classic-header {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	@include group(15px, 8px);
}

.quote-classic-profile {
	display: flex;
	align-items: center;
	
	> * + * {
		margin-left: 22px;
	}
}

.quote-classic-links {
	position: relative;
	top: -5px;
	display: flex;
	align-items: center;
	
	> * + * {
		margin-left: 10px;
	}
}

.quote-classic-social-link {
	font-size: 22px;
	line-height: 1;
	color: $body-color;
	
	&[class*='facebook'] {
		color: $facebook;
	}
	
	&:hover {
		color: $primary;
	}
}

.quote-classic-avatar {
	width: 100%;
	max-width: 56px;
  border-radius: 50%;
}

.quote-classic-text {
	padding-left: 10px;
	color: $headings-color;
}

.quote-classic-time {
	display: block;
	text-align: right;
	color: rgba($black, 0.4);
}

* + .quote-classic-text { margin-top: 10px; }
* + .quote-classic-time { margin-top: 10px; }

@include media-breakpoint-up(sm) {
  .quote-classic {
		padding: 40px 25px;
	}
	
	* + .quote-classic-text { margin-top: 15px; }
}

@include media-breakpoint-up(lg) {
	.quote-classic {
		padding-top: 50px;
		padding-bottom: 40px;
	}

	.quote-classic-avatar {
		max-width: 84px;
	}

	.quote-classic-social-link {
		font-size: 30px;
	}
	
	.quote-classic-text {
    font-size: 18px;
		line-height: (31 / 18);
  }
	
	.quote-classic-time {
		font-size: 18px;
	}

	* + .quote-classic-text { margin-top: 20px; }
	* + .quote-classic-time { margin-top: 20px; }
}

//
// Quote Light
//
.quote-light {

}

.quote-light-sm {
	.quote-light-cite {
		padding-left: 110px;
		&::before {
			display: none;
		}	
	}
}

.quote-light-mark {
	font-size: 60px;
  color: $primary;
}

.quote-light-text {
	font-size: 15px;
	line-height: 1.3;
	font-weight: 400;
	letter-spacing: .02em;
	color: #353535;
	q {
		&::before,
		&::after {
			content: '';
		}
	}
}

.quote-light-cite {
	position: relative;
	display: block;
	line-height: 1.3;
	font-weight: 600;
	color: $black;

	&::before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		width: 30px;
		margin-right: 20px;
		height: 1px;
		background: $gray-500;
	}
}

.quote-light-avatar {
	position: relative;
	overflow: hidden;
	width: 100%;
	max-width: 94px;
	background: none;
  border: none;
	border-radius: 50%;
	img {
		display: block;
		width: 100%;
	}
}

.quote-light-avatar {
	@include reset-button;
	display: block;
	transition: .22s;

	&:hover {
		box-shadow: 0 4px 10px 0 rgba($black, .3);
	}
	&.active {
		box-shadow: 0 8px 20px 0 rgba($black, .35);
	}
}

* + .quote-light-main { margin-top: 13px; }
* + .quote-light-cite { margin-top: 13px; }

@media (min-width: 480px) {
  .quote-light {
		display: flex;
		align-items: flex-start;


		> * + * {
			margin-left: 20px;
		}
	}

	.quote-light-mark {
		flex-shrink: 0;
	}

	* + .quote-light-main { margin-top: 0; }
}

@include media-breakpoint-up(sm) {
	.quote-light-sm {
		.quote-light-cite {
			padding-left: 130px;
		}
	}
	
	.quote-light-text {
		font-size: 17px;
	}
}

@include media-breakpoint-up(md) {
	.quote-light {
		> * + * {
			margin-left: 30px;
		}
	}

	.quote-light-sm {
		.quote-light-text {
			font-size: 16px;
		}
		
		> * + * {
			margin-left: 23px;
		}
	}

	.quote-light-mark {
		position: relative;
		top: -10px;
	}

  .quote-light-text {
		font-size: 20px;
		line-height: 1.7;
	}

	.quote-light-cite {
		font-size: 18px;
		&::before {
			width: 70px;
			margin-right: 40px;
		}
	}

	* + .quote-light-cite { margin-top: 20px; }
}

@include media-breakpoint-up(lg) {
	.quote-light-sm {
		.quote-light-text {
			font-size: 17px;
		}
	}
	
	* + .quote-light-cite { margin-top: 25px; }
}

@include media-breakpoint-up(xl) {
	.quote-light-sm {
		.quote-light-text {
			font-size: 18px;
		}
		* + .quote-light-cite { margin-top: 30px; }
	}
	
  .quote-light-text {
		font-size: 20px;
	}

	* + .quote-light-cite { margin-top: 50px; }
}


//
// Quote Light 2
//



.fs-0 {
	flex-shrink: 0;
}

.owl-group-3{
	.quote-light-cite{
		font-size: 22px;
	}
}