/*
*
* Countdown
*/

//
// Table of Contents
//
// Countdown Classic
//

//
// Countdown Classic
//
.countdown-classic {
	.countdown-row {
		display: flex;
		align-items: flex-end;
	}
	
	.countdown-section {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.countdown-amount {
		font-size: 30px;
		line-height: 1;
		font-weight: 500;
		color: $primary;
		min-width: 1.5em;
		text-align: center;
	}

	.countdown-period {
		font-size: 13px;
		font-weight: 500;
		text-transform: uppercase;
		color: $gray-500;
	}

	.countdown-section + .countdown-section { margin-left: 25px; }
}


* + .countdown-classic { margin-top: 30px; }

@include media-breakpoint-up(md) {
	.countdown-classic {
		.countdown-amount {
			font-size: 36px;
		}
	}
	
	* + .countdown-classic { margin-top: 40px; } 
}
