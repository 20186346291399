/*
*
* Destinations
*/

//
// Table of Contents
//
// Destination 1
//

//
// Destination 1
//
.destination-1 {
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	max-width: 360px;
	margin-left: auto;
	margin-right: auto;
	
	&, 
	&:active, 
	&:focus, 
	&:hover {
	  color: $white;
	}
}

.destination-1-figure {
	position: relative;
	width: 100%;
	border-radius: 8px;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		border-radius: inherit;
		background: linear-gradient(to top, rgba($black, .3), rgba($black, .2));
		opacity: .5;
		z-index: 1;
	}

	&::after {
		content: '';
		display: block;
		width: 100%;
		opacity: 0;
		padding-bottom: (260 / 363) * 100%;
		visibility: hidden;
	}
}

.destination-1-image {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	border-radius: inherit;
}

.destination-1-caption {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	z-index: 3;
	width: 100%;
	padding: 15px;
}

.destination-1-decoration-title {

	font-size: 110px;
	line-height: .7;
	white-space: nowrap;
	text-align: left;
	color: rgba($white, .17);
	margin-top: -.4em;
	margin-left: -15px;
	margin-bottom: -.3em;
}

.destination-1-title {
	line-height: 1.2;
	font-weight: 600;
	text-shadow: 0 8px 15px rgba(0, 0, 0, 0.4);
}

.destination-1-comment {
	position: relative;
	z-index: 2;
	display: inline-block;
	padding: 8px 20px;
	border-radius: 20px;
	color: $primary;
	background: $white;
	font-weight: 900;
	font-size: 11px;
	text-transform: uppercase;
}

* + .destination-1-comment { margin-top: 9px; }

@include media-breakpoint-between(sm, sm) {
	.destination-1-decoration-title {
		font-size: 90px;
	}

	.destination-1-comment {
		font-size: 16px;
	}
}

@include media-breakpoint-up(lg) {
	.destination-1-decoration-title {
		font-size: 150px;
	}

	html:not(.tablet):not(.mobile) {
		.destination-1-figure {
			transform: scale(1, 1);
			transition: .5s;
		}

		.destination-1-decoration-title,
		.destination-1-title,
		.destination-1-comment {
			transition: .3s;
		}

		.destination-1-decoration-title {
			opacity: 0;
			visibility: hidden;
			transform: scale(1.2);
			transition: .5s;
		}

		.destination-1-title {
			position: relative;
			transform: translate3d(0, 50%, 0);
		}

		.destination-1-comment {
			opacity: 0;
			visibility: hidden;
			transform: translate3d(0, 20px, 0);
			transition-delay: .03s;
		}

		.destination-1:hover {
			.destination-1-figure {
				transform: scale3d(1.1, 1.1, 1);
			}

			.destination-1-decoration-title {
				opacity: 1;
				visibility: visible;
				transform: scaleZ(7.06);
			}

			.destination-1-title {
				transform: translate3d(0, 0, 0);
			}

			.destination-1-comment {
				opacity: 1;
				visibility: visible;
				transform: translate3d(0, 0, 0);
			}
		}
	}
}