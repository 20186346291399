/*
*
* Modal Custom
*/

.modal {
	display: block;
	padding: 30px 15px 7vh;
	visibility: hidden;
	transition: .22s;
	
	.close {
		cursor: pointer;
		color: $gray-400;
		transition: .22s;
		
		span {
			&::before {
				content: '\f24c';
				font-family: 'Material Design Icons';
			}
		}
		
		&:hover {
			color: $gray-700;
		}
	}
}

.modal.show {
	visibility: visible;
}

.modal.fade .modal-dialog {
	transform: translate3d(0, -30px, 0);
}

.modal.show .modal-dialog {
	transform: translate3d(0, 0, 0);
}

.modal-dialog {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	height: 100%;
}

.modal-inner {
	width: 100%;
}

.modal-content {
	height: 100%;
	border-radius: $border-radius-lg;
}

.modal-body {
	height: 100%;
	overflow-y: auto;
}

@include media-breakpoint-up(md) {
	.modal-header,
	.modal-body {
		padding-left: 30px;
		padding-right: 30px;
	}
	
	.modal-header {
		padding-top: 20px;
		padding-bottom: 20px;
	}
	
	.modal-body {
		padding-top: 30px;
		padding-bottom: 30px;
	}
	
	.modal-dialog {
		max-width: 800px;
	}
}
